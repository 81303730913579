import React from "react";
import { dataTime } from "../data/data";
import { Tooltip } from "antd";
const SetTime2 = ({ date, idSan, data }) => {
  const filteredData =
    data && data.filter((item) => item.san === idSan && item.date === date);

  return (
    <>
      <>
        <div>
          <div
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
              display: "grid",
              gap: "20px",
            }}
          >
            {dataTime?.map((timeObj, index) => {
              const booking = filteredData?.find((item) =>
                item?.time?.includes(timeObj?.time)
              );

              let buttonClass = "";
              if (booking) {
                buttonClass =
                  booking?.status === "Chờ xác nhận"
                    ? "btn_xacnhan"
                    : "btn_dadat";
              }
              const tooltipContent = booking ? (
                <div>
                  <p>
                    <strong>Tên:</strong> {booking.updateBy.name}
                  </p>
                  <p>
                    <strong>Số điện thoại:</strong> {booking.updateBy.mobile}
                  </p>
                  <p>
                    <strong>Thời gian:</strong> {booking.time.join(", ")}
                  </p>
                  <p>
                    <strong>Ngày:</strong> {booking.date}
                  </p>
                  <p>
                    <strong>Tình trạng:</strong> {booking.status}
                  </p>
                </div>
              ) : (
                "Thời gian trống"
              );
              return (
                <Tooltip
                  key={index}
                  title={tooltipContent}
                  placement="top"
                  mouseEnterDelay={0.5} // Adjust delay as needed
                >
                  <button
                    type="button"
                    style={{
                      borderRadius: "10px",
                    }}
                    className={buttonClass}
                    key={index}
                  >
                    {timeObj?.time}
                  </button>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </>
    </>
  );
};

export default SetTime2;
