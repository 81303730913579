import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import quanService from "./QuanService";

export const getquans = createAsyncThunk(
  "quan/get-all-quan",
  async (thunkAPI) => {
    try {
      return await quanService.getquan();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getIdquan = createAsyncThunk(
  "quan/get-id-quan",
  async (id, thunkAPI) => {
    try {
      return await quanService.getIdquan(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatequan = createAsyncThunk(
  "quan/update-quan",
  async (quanData, thunkAPI) => {
    console.log(quanData);
    try {
      return await quanService.updatequan(quanData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createquan = createAsyncThunk(
  "quan/create-quan",
  async (quan, thunkAPI) => {
    try {
      return await quanService.createquan(quan);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletequan = createAsyncThunk(
  "quan/delete-quan",
  async (id, thunkAPI) => {
    try {
      return await quanService.deletequan(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  quans: [],
  createquan: "",
  aquan: "",
  quanName: "",
  updateName: "",
  deletequan: {},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const QuanSlice = createSlice({
  name: "quan",
  initialState,
  reducers: {},
  // trang thai
  extraReducers: (builder) => {
    builder
      .addCase(getquans.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getquans.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Get all quan successfully";
        state.quans = action.payload;
      })
      .addCase(getquans.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });

    // create
    builder
      .addCase(createquan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createquan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "createGet all quan successfully";
        state.createquan = action.payload;
      })
      .addCase(createquan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });

    // get id
    builder
      .addCase(getIdquan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdquan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Get id quan successfully";
        state.aquan = action.payload;
      })
      .addCase(getIdquan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });

    // update
    builder
      .addCase(updatequan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatequan.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.message = "update quan successfully";
        state.updateName = action.payload?.udquan;
        state.isLoading = false;
      })
      .addCase(updatequan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });

    // delete
    builder
      .addCase(deletequan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletequan.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.message = "delete quan successfully";
        state.deletequan = action.payload;
        state.isLoading = false;
      })
      .addCase(deletequan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(resetState, () => initialState);
  },
});

export default QuanSlice.reducer;
