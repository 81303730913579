import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt, FaCalendarAlt, FaStar } from "react-icons/fa";
import { BsCalendar2HeartFill } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { SiLevelsdotfyi } from "react-icons/si";
import { IoMdPricetag } from "react-icons/io";
import Rating from "@mui/material/Rating";
import FeedBack from "../components/FeedBack";
import { useDispatch, useSelector } from "react-redux";
import { getPost, postRegister } from "../features/post/postSlice";
import { options, option4, option5, option6 } from "../data/data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { base_url } from "../utils/base_url";
import axios from "axios";
import { config } from "../utils/axiosconfig";
import { Select } from "antd";

const formatCurrencyToK = (amount) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  let formattedAmount = formatter.format(amount);

  formattedAmount = formattedAmount = formattedAmount.replace(/\s*₫/, "Đ");

  return formattedAmount;
};
const userSchema = Yup.object().shape({
  quantity: Yup.number().required("Nhập số lượng đăng ký"),
});
const BadmintonDetails = () => {
  const [clickTG, setClickTG] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPost(id));
    window.scroll(0, 0);
  }, [dispatch, id]);

  const post = useSelector((state) => state.post.getPost.getPost);
  const user = useSelector((state) => state.auth.user);
  const [clickFB, setClickFB] = useState(false);
  const [clickUserRegister, setClickUserRegister] = useState(false);

  const getLBV = (value) => {
    const numericValue = parseInt(value, 10);
    const option = options.find((option) => option.value === numericValue);
    return option ? option.label : value;
  };
  const getLabelByValue = (options) => (value) => {
    const option = options.find(
      (option) => option.value === parseInt(value, 10)
    );
    return option ? option.label : value;
  };
  const getLBVCity = getLabelByValue(option4);
  const getLBVQuan = getLabelByValue(option5);
  const getLBVSan = getLabelByValue(option6);
  const formik = useFormik({
    initialValues: {
      quantity: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      const data = {
        quantity: values.quantity,
        postId: id,
      };
      console.log(values, data);
      if (user === null) {
        toast.warning("Vui lòng đăng nhập");
      } else {
        dispatch(postRegister(data));
        setTimeout(() => {
          formik.resetForm();
          dispatch(getPost(id));
        }, 300);
      }
    },
  });
  const [commentContent, setCommentContent] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [showAllReplies, setShowAllReplies] = useState({});
  const [activeReply, setActiveReply] = useState(null); // Quản lý bình luận đang được trả lời
  const handleCommentSubmit = async () => {
    try {
      const response = await axios.post(
        `${base_url}post/addComment`,
        {
          postId: post._id,
          content: commentContent,
        },
        config
      );
      console.log("New Comment Response:", response.data);
      setCommentContent("");
      dispatch(getPost(id));
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
  const commentsToShow = showAllComments
    ? post?.comments
    : post?.comments.slice(0, 3);
  const isVideo = (url) => {
    return url && url.includes("video");
  };
  const toggleShowAllReplies = (commentId) => {
    setShowAllReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };
  const handleReplySubmit = async (commentId) => {
    try {
      const response = await axios.post(
        `${base_url}post/replyToComment`,
        {
          postId: post._id,
          commentId,
          content: replyContent,
        },
        config
      );
      console.log("Reply Response:", response.data);
      setReplyContent("");
      setActiveReply(null);
      dispatch(getPost(id));
    } catch (error) {
      console.error("Error submitting reply:", error);
    }
  };
  const handleChange = async (value) => {
    try {
      const response = await axios.post(
        `${base_url}post/updatesan`,
        {
          _id: post._id,
          status: value,
        },
        config
      );
      setTimeout(() => {
        dispatch(getPost(id));
        toast.success("Đã cập nhật trạng thái");
      }, 300);
    } catch (error) {
      console.error("Error submitting reply:", error);
    }
  };
  const stars = Array.from({ length: 5 }, (_, index) => (
    <FaStar
      key={index}
      size={10}
      className="star start-size mb-1"
      color={index < post?.totalRating ? "#ffc107" : "#e4e5e9"}
    />
  ));
  return (
    <div className="mt-5">
      <Container>
        <Row>
          <Col sm={8}>
            <div>
              <div
                style={{
                  position: "relative",
                }}
              >
                {post?.images && post.images[0]?.url ? (
                  isVideo(post.images[0].url) ? (
                    <video
                      src={post.images[0].url}
                      alt="video preview"
                      style={{
                        width: "100%",
                        height: "300px",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      className="media-mobile-cours"
                      controls
                    />
                  ) : (
                    <img
                      src={post.images[0].url}
                      alt="image preview"
                      style={{
                        width: "100%",
                        height: "350px",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      className="img-mobile-cours"
                    />
                  )
                ) : (
                  <p>No media available</p>
                )}
                <button
                  className="btn btn-danger"
                  style={{
                    borderRadius: "20px",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  }}
                >
                  Giá thuê :{" "}
                  {formatCurrencyToK(post?.priceNu) +
                    " - " +
                    formatCurrencyToK(post?.priceNam)}
                </button>
              </div>
              <div className="py-4">
                <strong className="fs-3">{post?.title}</strong>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <FaMapMarkerAlt
                    style={{
                      color: "red",
                    }}
                  />
                  <span>
                    {getLBVSan(post?.san) +
                      ", " +
                      getLBVQuan(post?.quan) +
                      ", " +
                      getLBVCity(post?.city)}
                  </span>
                </div>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <FaCalendarAlt
                    style={{
                      color: "red",
                    }}
                  />
                  <span>
                    {post?.date +
                      " / " +
                      post?.timeStart +
                      " đến " +
                      post?.timeEnd}
                  </span>
                </div>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <BsCalendar2HeartFill
                    style={{
                      color: "red",
                    }}
                  />
                  <span>{post?.laplai === 1 ? "Hàng ngày" : "Hàng tuần"}</span>
                </div>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <FaUsers
                    style={{
                      color: "red",
                    }}
                  />
                  <span>Cần tuyển : {post?.countct}</span>
                </div>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <SiLevelsdotfyi
                    style={{
                      color: "red",
                    }}
                  />
                  <span>
                    Trình độ: {getLBV(post?.minTrinhDo)} đến{" "}
                    {getLBV(post?.maxTrinhDo)}
                  </span>
                </div>
                <div className="fs-5 d-flex gap-2 align-items-center">
                  <IoMdPricetag
                    style={{
                      color: "red",
                    }}
                  />
                  <span>
                    Phí giao lưu :{" "}
                    {formatCurrencyToK(post?.priceNu) +
                      " - " +
                      formatCurrencyToK(post?.priceNam)}
                  </span>
                </div>
                <div>
                  <strong className="fs-4">Mô tả</strong>
                  <p>{post?.description}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div
              style={{
                border: "1px solid black",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <strong className="fs-5 ">Người đăng tin</strong>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-2">
                    <FaRegUserCircle />
                    {/* {post?.user.image} */}
                  </span>
                  <span className="fs-4">{post?.updateBy?.name}</span>
                </div>
                <hr />
                <div>
                  <strong className="fs-5">Liên hệ</strong>
                  <div className="d-flex flex-column">
                    <span>SDT: {post?.updateBy?.mobile}</span>
                    <span className="d-flex align-items-center">
                      <p className="mb-1 me-2">Điểm uy tín: </p>
                      {stars}
                    </span>
                    <span>
                      Số người đăng ký :{" "}
                      {post?.totalRegister > 0 ? post?.totalRegister : 0}
                    </span>
                    <span>
                      Trạng thái sân : <strong>{post?.status}</strong>
                    </span>
                    <Link to={`${post?.links}`} target="_blank">
                      Link facebook cá nhân
                    </Link>
                  </div>
                  <div className="d-flex gap-2 flex-column ">
                    {user?._id === post?.updateBy?._id && (
                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <lable>Cập nhật trạng thái sân</lable>
                        <Select
                          defaultValue="Pass"
                          placeholder="Cập nhật trạng thái sân"
                          style={{
                            width: 200,
                          }}
                          onChange={handleChange}
                          options={[
                            {
                              label: <span>Lựa chọn</span>,
                              title: "Lựa chọn",
                              options: [
                                {
                                  label: <span>Pass</span>,
                                  value: "Pass",
                                },
                                {
                                  label: <span>Đã Pass</span>,
                                  value: "Đã Pass",
                                },
                              ],
                            },
                          ]}
                        />
                      </div>
                    )}
                    <div className="">
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => setClickTG(!clickTG)}
                      >
                        Đăng ký giao lưu
                      </button>
                    </div>
                    {clickTG && (
                      <div className="d-flex align-items-center">
                        <form
                          onSubmit={formik.handleSubmit}
                          className="d-flex align-items-center gap-2"
                        >
                          <input
                            min={0}
                            type="number"
                            style={{
                              // width:
                              //   "fit-content",
                              padding: "4px 8px",
                            }}
                            placeholder="Số lượng đăng ký"
                            value={formik.values.quantity}
                            onChange={formik.handleChange("quantity")}
                          />
                          <button className="btn btn-danger" type="submit">
                            Đăng ký
                          </button>
                        </form>
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => setClickUserRegister(!clickUserRegister)}
                      >
                        Thông tin người đã đăng ký
                      </button>
                    </div>
                  </div>
                  {clickUserRegister && (
                    <div className="pt-3">
                      {post?.history?.map((hs) => {
                        // Kiểm tra nếu user?._id === post?.updateBy?._id
                        const isSameUser = user?._id === post?.updateBy?._id;

                        // Che 3 số cuối nếu không phải cùng một người dùng
                        const maskedMobile = isSameUser
                          ? hs.userId.mobile
                          : hs.userId.mobile.replace(/(\d{3})$/, "***");

                        return (
                          <div
                            key={hs._id}
                            className="mt-2"
                            style={{
                              border: "1px solid black",
                              width: "max-content",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <span>Tên: {hs.userId.name}</span> -{" "}
                            <span>SĐT: {maskedMobile}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <strong className="fs-4">Bình luận</strong>
            <hr />
            <div>
              {/* Biểu mẫu bình luận */}
              <div className="comment-form">
                <textarea
                  className="form-control"
                  rows="3"
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Viết bình luận..."
                />
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleCommentSubmit}
                >
                  Gửi bình luận
                </button>
              </div>
              {/* Hiển thị danh sách bình luận */}
              {commentsToShow?.map((comment) => (
                <div key={comment?._id} className="comment-section">
                  <div className="comment">
                    <div className="comment-author  d-flex align-items-center gap-2">
                      <FaRegUserCircle
                        style={{
                          color: "black",
                        }}
                      />{" "}
                      <strong
                        style={{
                          color: "black",
                        }}
                      >
                        {comment?.postedBy?.name}
                      </strong>
                    </div>
                    <div className="comment-content">{comment?.content}</div>
                    {/* Hiển thị trả lời bình luận */}
                    {comment?.replies?.length > 3 ? (
                      <>
                        {(showAllReplies[comment?._id]
                          ? comment?.replies
                          : comment?.replies?.slice(0, 3)
                        ).map((reply) => (
                          <div key={reply._id} className="reply-section">
                            <div className="reply-author  d-flex align-items-center gap-2">
                              <FaRegUserCircle
                                style={{
                                  color: "black",
                                }}
                              />{" "}
                              <strong
                                style={{
                                  color: "black",
                                }}
                              >
                                {reply?.postedBy?.name}
                              </strong>
                            </div>
                            <div className="reply-content">
                              {reply?.content}
                              <p
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {(reply?.createdAt).substring(0, 10)} lúc{" "}
                                {(reply?.createdAt).substring(11, 19)}{" "}
                              </p>
                            </div>
                          </div>
                        ))}
                        <button
                          className="btn btn-link"
                          onClick={() => toggleShowAllReplies(comment?._id)}
                        >
                          {showAllReplies[comment?._id] ? "Ẩn bớt" : "Xem thêm"}
                        </button>
                      </>
                    ) : (
                      comment?.replies?.map((reply) => (
                        <div key={reply?._id} className="reply-section">
                          <div className="reply-author d-flex align-items-center gap-2">
                            <FaRegUserCircle
                              style={{
                                color: "black",
                              }}
                            />{" "}
                            <strong
                              style={{
                                color: "black",
                              }}
                            >
                              {reply?.postedBy?.name}
                            </strong>
                          </div>

                          <div className="reply-content">
                            {reply.content}
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {(reply?.createdAt).substring(0, 10)} lúc{" "}
                              {(reply?.createdAt).substring(11, 19)}{" "}
                            </p>
                          </div>
                        </div>
                      ))
                    )}
                    {/* Biểu mẫu trả lời bình luận */}
                    {activeReply === comment?._id && (
                      <div className="reply-form">
                        <textarea
                          className="form-control"
                          rows="2"
                          value={replyContent}
                          onChange={(e) => setReplyContent(e.target.value)}
                          placeholder="Trả lời bình luận..."
                        />
                        <button
                          className="btn btn-secondary mt-2"
                          onClick={() => handleReplySubmit(comment?._id)}
                        >
                          Gửi trả lời
                        </button>
                      </div>
                    )}
                    <button
                      className="btn btn-link"
                      onClick={() => setActiveReply(comment?._id)}
                    >
                      Trả lời
                    </button>
                  </div>
                </div>
              ))}
              {/* Nút xem thêm hoặc ẩn bớt bình luận */}
              {post?.comments?.length > 3 && (
                <button
                  className="btn btn-link"
                  onClick={() => setShowAllComments(!showAllComments)}
                >
                  {showAllComments ? "Ẩn bớt" : "Xem thêm"}
                </button>
              )}
            </div>
          </div>

          <div
            style={{
              border: "1px solid white",
              borderRadius: "20px",
              padding: "10px",
            }}
          >
            <strong className="fs-4">Đánh giá</strong>
            <hr />
            <div>
              {post?.ratings.length === 0 ? (
                <span className="d-flex">Chưa có đánh giá nào</span>
              ) : (
                <>
                  {post?.ratings.map((fback) => (
                    <div key={fback._id}>
                      <div className="d-flex flex-col gap-2">
                        <span className="fw-bold text-yellow-300">
                          {fback.postedby?.name}
                        </span>
                        <div>
                          <Rating
                            name="size-small"
                            defaultValue={fback.star}
                            value={fback.star}
                            size="small"
                            readOnly
                          />
                        </div>
                      </div>
                      <div>
                        <p>{fback.comment}</p>
                      </div>
                      <hr />
                    </div>
                  ))}
                </>
              )}
              {post?.totalRegister > 0 ? (
                <>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => setClickFB(true)}
                  >
                    Viết đánh giá
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          {clickFB && <FeedBack onClose={() => setClickFB(false)} />}
        </Row>
      </Container>
    </div>
  );
};

export default BadmintonDetails;
