import React, { useEffect, useState } from "react";
import {
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaStar,
  FaPhoneAlt,
} from "react-icons/fa";
import { BsCalendar2HeartFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SiLevelsdotfyi } from "react-icons/si";

import Product from "./Product";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../features/post/postSlice";
import Map from "./Map";
import { option4, option5, option6, options } from "../data/data";
import { getAllproduct } from "../features/product/productSlice";
import { IoMdPricetag } from "react-icons/io";
import { getSlides } from "../features/slide/SlideSlice";
import Map2 from "./Map2";
const formatCurrencyToK = (amount) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  let formattedAmount = formatter.format(amount);
  formattedAmount = formattedAmount.replace(/\s*₫/, "");

  return formattedAmount;
};

const BadmintonCourt = ({ idName, data, checked }) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const updateCheck = () => {
      if (window.innerWidth <= 768) {
        setCheck(true);
      } else {
        setCheck(false);
      }
    };
    updateCheck();
    window.addEventListener("resize", updateCheck);
    return () => {
      window.removeEventListener("resize", updateCheck);
    };
  }, []);
  const location = useLocation();
  const locations = ["Hồ Gươm, Hà Nội"];
  const idslug = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const isVideo = (url) => {
    return url && url.includes("video");
  };
  useEffect(() => {
    dispatch(getAllPost());
    dispatch(getAllproduct());
    dispatch(getSlides());
  }, [dispatch]);
  const slides = useSelector((state) => state.slide.Slides);

  const postAll = useSelector((state) => state.post.Posts);
  const postAlll = useSelector((state) => state?.post?.post.Posts);
  const productAll = useSelector((state) => state?.product?.product?.products);
  const sliceProduct = productAll?.slice(0, 8);
  const getLBV = (value) => {
    const numericValue = parseInt(value, 10);
    const option = options.find((option) => option.value === numericValue);
    return option ? option.label : value;
  };
  const filterGL = postAlll.filter((item) => item.type === "Giao lưu");
  const [pathname, setPathName] = useState("");

  useEffect(() => {
    if (location.pathname === "/home/sandau") {
      setPathName("Sân đấu");
    } else if (location.pathname === "/home/giaoluu") {
      setPathName("Giao lưu");
    } else {
      setPathName("Trao đổi");
    }
  }, [location.pathname]);

  const filterPostAllPathName = postAlll?.filter(
    (item) => item.type === pathname
  );

  const filteredPosts = postAlll?.filter((post) => {
    if (idslug === "traodoi" && post.type === "Trao đổi") return true;
    if (idslug === "sandau" && post.type === "Sân đấu") return true;
    if (idslug === "giaoluu" && post.type === "Giao lưu") return true;
    return false;
  });

  const getLabelByValue = (options) => (value) => {
    const option = options.find(
      (option) => option.value === parseInt(value, 10)
    );
    return option ? option.label : value;
  };

  const getLBVCity = getLabelByValue(option4);
  const getLBVQuan = getLabelByValue(option5);
  const getLBVSan = getLabelByValue(option6);

  const displayPost = postAll === null ? filterPostAllPathName : filteredPosts;

  return (
    <div className="hide-map">
      {checked === true ? (
        <div className="d-flex flex-column gap-3">
          {idName === "traodoi" && (
            <div className="d-flex flex-column gap-3">
              <div className="w-full">
                {slides
                  ?.filter((item) => item.vitri === "Vị trí 1")
                  .map((item, index) => (
                    <a
                      href={item.links}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <img
                        src={item.images[0]?.url}
                        style={{
                          height: "300px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        alt="Slide Image"
                      />
                    </a>
                  ))}
              </div>
              <strong>Một vài sản phẩm</strong>
              <div>
                <div
                  className={`d-flex hometraodoi  ${
                    check === true ? "" : "p-4"
                  } `}
                  style={{
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "25px",
                  }}
                >
                  {sliceProduct?.length > 0 ? (
                    sliceProduct?.map((item) => (
                      <div
                        style={{
                          background: "white",
                          padding: "10px",
                          borderRadius: "20px",
                          marginBottom: "5px",
                          width: "23%",
                          boxShadow: "#ccc 1px 1px 10px",
                        }}
                        className="bnn"
                        key={item?._id}
                      >
                        <div className="d-flex gap-3 flex-column jkl">
                          <Link to={`/product/${item?._id}`}>
                            <div style={{ position: "relative" }}>
                              <img
                                src={item?.images[0]?.url}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "166px",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                                className="img-mobile-cours"
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                  borderRadius: "20px",
                                  background: "#f5002f",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                                className="btn btn-danger"
                              >
                                {formatCurrencyToK(item?.price)}
                              </button>
                            </div>
                          </Link>
                          <div className="d-flex flex-column justify-content-evenly">
                            <Link to={`/product/${item?._id}`}>
                              <h4 className="text-dark">{item?.title}</h4>
                            </Link>
                            <div className="d-flex gap-3 align-items-center">
                              <FaMapMarkerAlt style={{ color: "red" }} />
                              <span style={{ color: "#5b5b5b" }}>
                                Tại {item?.city}
                              </span>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <FaPhoneAlt style={{ color: "red" }} />
                              <span style={{ color: "#5b5b5b" }}>
                                {item?.mobile}
                              </span>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <IoMdPricetag style={{ color: "red" }} />
                              <span
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                {formatCurrencyToK(item?.price)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No products found.</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <strong>Tìm thấy {filteredPosts?.length} hoạt động</strong>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
              paddingTop: "16px",
              ...(idName === "traodoi"
                ? {
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: "25px",
                  }
                : {}),
            }}
            className="flex-col-mobile"
          >
            {filteredPosts?.map((inf) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "10px",
                    borderRadius: "20px",
                    ...(idName === "traodoi" || filteredPosts.length === 1
                      ? {
                          width: "49%",
                        }
                      : {
                          width: "100%",
                        }),
                    boxShadow: "#ccc 1px 1px 10px",
                  }}
                  key={inf?._id}
                  className="bnn"
                >
                  <div className="d-flex gap-3 jkl">
                    <Link to={`/badminton/${inf?._id}`}>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        {inf?.images && inf.images[0]?.url ? (
                          isVideo(inf.images[0].url) ? (
                            <video
                              src={inf.images[0].url}
                              alt="video preview"
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "20px",
                              }}
                              className="media-mobile-cours mobileCSS"
                              controls
                            />
                          ) : (
                            <img
                              src={inf.images[0].url}
                              alt="image preview"
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "20px",
                              }}
                              className="img-mobile-cours"
                            />
                          )
                        ) : (
                          <p>No media available</p>
                        )}
                        <button
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                            borderRadius: "20px",
                            background: "#f5002f",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                          className="btn btn-danger"
                        >
                          {formatCurrencyToK(inf?.priceNu) +
                            " - " +
                            formatCurrencyToK(inf?.priceNam)}
                        </button>
                      </div>
                    </Link>

                    <div className="d-flex flex-column justify-content-evenly">
                      <span
                        style={{
                          color: "#5b5b5b",
                        }}
                      >
                        Đang xác định vị trí của bạn
                      </span>
                      <Link to={`/badminton/${inf?._id}`}>
                        <h4 className="text-dark">{inf?.title}</h4>
                      </Link>
                      <div>
                        <p>Người đăng tuyển :{" " + inf?.updateBy?.name}</p>
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        <FaMapMarkerAlt
                          style={{
                            color: "red",
                          }}
                        />
                        <span
                          style={{
                            color: "#5b5b5b",
                          }}
                        >
                          {getLBVSan(inf?.san) +
                            ", " +
                            getLBVQuan(inf?.quan) +
                            ", " +
                            getLBVCity(inf?.city)}
                        </span>
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        <FaCalendarAlt
                          style={{
                            color: "red",
                          }}
                        />
                        <span
                          style={{
                            color: "#5b5b5b",
                          }}
                        >
                          {inf?.date +
                            " " +
                            inf?.timeStart +
                            " " +
                            inf?.timeEnd}
                        </span>
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        <BsCalendar2HeartFill
                          style={{
                            color: "red",
                          }}
                        />
                        <span
                          style={{
                            color: "#5b5b5b",
                          }}
                        >
                          {inf?.laplai === 1 ? "Hàng ngày" : "Hàng tuần"}
                        </span>
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        <SiLevelsdotfyi
                          style={{
                            color: "red",
                          }}
                        />
                        <span
                          style={{
                            color: "#5b5b5b",
                          }}
                        >
                          Trình độ:
                          {getLBV(inf?.minTrinhDo)} đến{" "}
                          {getLBV(inf?.maxTrinhDo)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <Row>
            <Col sm={idName === "traodoi" ? 12 : 8}>
              <div className="d-flex flex-column gap-3 w-full">
                {idName === "traodoi" && (
                  <>
                    <div className="d-flex flex-column gap-3">
                      <div className="w-full">
                        {slides
                          ?.filter((item) => item.vitri === "Vị trí 1")
                          .map((item, index) => (
                            <a
                              href={item.links}
                              target="_blank"
                              rel="noopener noreferrer"
                              key={index}
                            >
                              <img
                                src={item.images[0]?.url}
                                style={{
                                  height: "300px",
                                  width: "100%",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                                alt="Slide Image"
                              />
                            </a>
                          ))}
                      </div>
                      <div className="d-flex justify-between w-full">
                        <strong>Một vài sản phẩm</strong>
                      </div>
                      <div>
                        <div
                          className={`d-flex hometraodoi ${
                            check === true ? "" : "p-4"
                          } `}
                          style={{
                            width: "100%",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: "25px",
                          }}
                        >
                          {sliceProduct?.length > 0 ? (
                            sliceProduct?.map((item) => (
                              <div
                                style={{
                                  background: "white",
                                  padding: "10px",
                                  borderRadius: "20px",
                                  marginBottom: "5px",
                                  width: "23%",
                                  boxShadow: "#ccc 1px 1px 10px",
                                }}
                                className="bnn"
                                key={item?._id}
                              >
                                <div className="d-flex gap-3 flex-column jkl">
                                  <Link to={`/product/${item?._id}`}>
                                    <div style={{ position: "relative" }}>
                                      <img
                                        src={item?.images[0]?.url}
                                        alt=""
                                        style={{
                                          width: "100%",
                                          height: "166px",
                                          objectFit: "cover",
                                          borderRadius: "20px",
                                        }}
                                        className="img-mobile-cours"
                                      />
                                      <button
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "10px",
                                          borderRadius: "20px",
                                          background: "#f5002f",
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                        className="btn btn-danger"
                                      >
                                        {formatCurrencyToK(item?.price)}
                                      </button>
                                    </div>
                                  </Link>
                                  <div className="d-flex flex-column justify-content-evenly">
                                    <Link to={`/product/${item?._id}`}>
                                      <h4 className="text-dark">
                                        {item?.title}
                                      </h4>
                                    </Link>
                                    <div className="d-flex gap-3 align-items-center">
                                      <FaMapMarkerAlt
                                        style={{ color: "red" }}
                                      />
                                      <span style={{ color: "#5b5b5b" }}>
                                        Tại {item?.city}
                                      </span>
                                    </div>
                                    <div className="d-flex gap-3 align-items-center">
                                      <FaPhoneAlt style={{ color: "red" }} />
                                      <span style={{ color: "#5b5b5b" }}>
                                        {item?.mobile}
                                      </span>
                                    </div>
                                    <div className="d-flex gap-3 align-items-center">
                                      <IoMdPricetag style={{ color: "red" }} />
                                      <span
                                        style={{
                                          color: "black",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {formatCurrencyToK(item?.price)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <strong className="">Không có sản phẩm nào.</strong>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <button className="w-full btn btn-primary d-flex justify-content-between align-items-center ">
                          <Link
                            to="/home/traodoi/mua-ban-traodoi"
                            style={{ color: "white" }}
                          >
                            Xem thêm
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div className="w-full mt-3">
                      {slides
                        ?.filter((item) => item.vitri === "Vị trí 2")
                        .map((item, index) => (
                          <a
                            href={item.links}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={index}
                          >
                            <img
                              src={item.images[0]?.url}
                              style={{
                                height: "300px",
                                width: "100%",
                                objectFit: "cover",
                                borderRadius: "20px",
                              }}
                              alt="Slide Image"
                            />
                          </a>
                        ))}
                    </div>
                  </>
                )}

                <strong>Tìm thấy {filteredPosts?.length} hoạt động</strong>
                <div
                  style={{
                    paddingTop: "16px",
                    ...(idName === "traodoi"
                      ? {
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          gap: "25px",
                        }
                      : {}),
                  }}
                  className="aaa"
                >
                  {filteredPosts?.map((inf) => {
                    return (
                      <div
                        style={{
                          background: "white",
                          padding: "10px",
                          borderRadius: "20px",
                          ...(idName === "traodoi"
                            ? {
                                width: "49%",
                              }
                            : {
                                width: "100%",
                                marginBottom: "20px",
                              }),

                          boxShadow: "#ccc 1px 1px 10px",
                        }}
                        className="bnn"
                        key={inf?._id}
                      >
                        <div className="d-flex gap-3 jkl">
                          <Link to={`/badminton/${inf?._id}`}>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              {inf?.images && inf.images[0]?.url ? (
                                isVideo(inf.images[0].url) ? (
                                  <video
                                    src={inf.images[0].url}
                                    alt="video preview"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      objectFit: "cover",
                                      borderRadius: "20px",
                                    }}
                                    className="media-mobile-cours mobileCSS"
                                    controls
                                  />
                                ) : (
                                  <img
                                    src={inf.images[0].url}
                                    alt="image preview"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      objectFit: "cover",
                                      borderRadius: "20px",
                                    }}
                                    className="img-mobile-cours"
                                  />
                                )
                              ) : (
                                <p>No media available</p>
                              )}
                              <button
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                  borderRadius: "20px",
                                  background: "#f5002f",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                                className="btn btn-danger"
                              >
                                {formatCurrencyToK(inf?.priceNu) +
                                  " - " +
                                  formatCurrencyToK(inf?.priceNam)}
                              </button>
                            </div>
                          </Link>

                          <div className="d-flex flex-column justify-content-evenly">
                            <span
                              style={{
                                color: "#5b5b5b",
                              }}
                            >
                              Đang xác định vị trí của bạn
                            </span>

                            <Link to={`/badminton/${inf?._id}`}>
                              <h4 className="text-dark">{inf?.title}</h4>
                            </Link>
                            <div className="d-flex align-items-center gap-20">
                              <p className="mb-0 me-4">
                                Người đăng tuyển :{" " + inf?.updateBy?.name}
                              </p>
                              <div className="d-flex gap-10">
                                {Array.from(
                                  {
                                    length: 5,
                                  },
                                  (_, index) => (
                                    <FaStar
                                      key={index}
                                      size={10}
                                      className="star start-size"
                                      color={
                                        index < inf?.totalRating
                                          ? "ffc107"
                                          : "#e4e5e9"
                                      }
                                    />
                                  )
                                )}
                              </div>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <FaMapMarkerAlt
                                style={{
                                  color: "red",
                                }}
                              />
                              <span
                                style={{
                                  color: "#5b5b5b",
                                }}
                              >
                                {getLBVSan(inf?.san) +
                                  ", " +
                                  getLBVQuan(inf?.quan) +
                                  ", " +
                                  getLBVCity(inf?.city)}
                              </span>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <FaCalendarAlt
                                style={{
                                  color: "red",
                                }}
                              />
                              <span
                                style={{
                                  color: "#5b5b5b",
                                }}
                              >
                                {inf?.date +
                                  " " +
                                  inf?.timeStart +
                                  " " +
                                  inf?.timeEnd}
                              </span>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <BsCalendar2HeartFill
                                style={{
                                  color: "red",
                                }}
                              />
                              <span
                                style={{
                                  color: "#5b5b5b",
                                }}
                              >
                                {inf?.laplai === 1 ? "Hàng ngày" : "Hàng tuần"}
                              </span>
                            </div>
                            <div className="d-flex gap-3 align-items-center">
                              <SiLevelsdotfyi
                                style={{
                                  color: "red",
                                }}
                              />
                              <span
                                style={{
                                  color: "#5b5b5b",
                                }}
                              >
                                Trình độ:
                                {getLBV(inf?.minTrinhDo)} đến{" "}
                                {getLBV(inf?.maxTrinhDo)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            {idName !== "traodoi" && (
              <Col sm={4} className="mappp">
                {checked && (
                  <div>
                    {/* <Map2 dataMap={postAlll} /> */}
                  </div>
                )}
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default BadmintonCourt;
