import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import { getAllproduct } from "../features/product/productSlice";
import { Row } from "react-bootstrap";

const Product = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sortOrder, setSortOrder] = useState("Thấp đến cao");
  const [filterStatus, setFilterStatus] = useState("Đang rao");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatCurrencyToK = (amount) => {
    const formatter = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    });

    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/\s*₫/, "Đ");

    return formattedAmount;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllproduct());
  }, [dispatch]);

  const products = useSelector((state) => state.product.product.products);
  const filteredProducts = useSelector((state) => state.product.filterProduct);

  const displayProducts =
    filteredProducts?.length > 0 ? filteredProducts : products;

  const sortedProducts =
    displayProducts &&
    [...displayProducts].sort((a, b) => {
      if (sortOrder === "Thấp đến cao") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    });

  const filteredAndSortedProducts = sortedProducts?.filter((product) => {
    return product.statusProduct === filterStatus;
  });

  const handleChangeSortOrder = (value) => {
    setSortOrder(value);
  };

  const handleChangeFilterStatus = (value) => {
    setFilterStatus(value);
  };

  return (
    <div>
      <div className="d-flex gap-5 optionmobile flex-wrap">
        <div className="p-4 ssmobile d-flex align-items-center py-4 gap-3 flex-wrap">
          <label>Sắp xếp</label>
          <Select
            defaultValue="Thấp đến cao"
            placeholder="Sắp xếp"
            style={{ width: 200 }}
            onChange={handleChangeSortOrder}
            options={[
              {
                label: "Thấp đến cao",
                value: "Thấp đến cao",
              },
              {
                label: "Cao đến thấp",
                value: "Cao đến thấp",
              },
            ]}
          />
        </div>
        <div className="p-4 ssmobile d-flex align-items-center py-4 gap-3 flex-wrap">
          <label>Trạng thái</label>
          <Select
            defaultValue="Đang rao"
            placeholder="Trạng thái"
            style={{ width: 200 }}
            onChange={handleChangeFilterStatus}
            options={[
              {
                label: "Shop nổi bật",
                value: "Đã bán",
              },
              {
                label: "Đang rao",
                value: "Đang rao",
              },
            ]}
          />
        </div>
      </div>
      {isMobile ? (
        <div
          className="d-flex flex-wrap my-5 px-4 gap-5 position-mobile"
          style={{ width: "100%" }}
        >
          {filteredAndSortedProducts?.length > 0 ? (
            filteredAndSortedProducts?.map((item) => (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  boxShadow: "#ccc 1px 1px 10px",
                }}
                className="bnn"
                key={item?._id}
              >
                <div className="d-flex gap-3 flex-column jkl">
                  <Link to={`/product/${item?._id}`}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={item?.images[0]?.url}
                        alt=""
                        style={{
                          width: "100%",
                          height: "200px !important",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          borderRadius: "20px",
                          background: "#f5002f",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="btn btn-danger"
                      >
                        {formatCurrencyToK(item?.price)}
                      </button>
                    </div>
                  </Link>
                  <div className="d-flex flex-column justify-content-evenly">
                    <Link to={`/product/${item?._id}`}>
                      <h4 className="text-dark">{item?.title}</h4>
                    </Link>
                    <div className="d-flex gap-3 align-items-center">
                      <FaMapMarkerAlt style={{ color: "red" }} />
                      <span style={{ color: "#5b5b5b" }}>Tại {item?.city}</span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <FaPhoneAlt style={{ color: "red" }} />
                      <span style={{ color: "#5b5b5b" }}>{item?.mobile}</span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <IoMdPricetag style={{ color: "red" }} />
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {formatCurrencyToK(item?.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-between align-items-center p-4"
          style={{
            width: "100%",
            flexWrap: "wrap",
            gap: "25px",
          }}
        >
          {filteredAndSortedProducts?.length > 0 ? (
            filteredAndSortedProducts?.map((item) => (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  marginBottom: "5px",
                  width: "23%",
                  boxShadow: "#ccc 1px 1px 10px",
                }}
                className="bnn"
                key={item?._id}
              >
                <div className="d-flex gap-3 flex-column jkl">
                  <Link to={`/product/${item?._id}`}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={item?.images[0]?.url}
                        alt=""
                        style={{
                          width: "100%",
                          height: "166px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          borderRadius: "20px",
                          background: "#f5002f",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="btn btn-danger"
                      >
                        {formatCurrencyToK(item?.price)}
                      </button>
                    </div>
                  </Link>
                  <div className="d-flex flex-column justify-content-evenly">
                    <Link to={`/product/${item?._id}`}>
                      <h4 className="text-dark">{item?.title}</h4>
                    </Link>
                    <div className="d-flex gap-3 align-items-center">
                      <FaMapMarkerAlt style={{ color: "red" }} />
                      <span style={{ color: "#5b5b5b" }}>Tại {item?.city}</span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <FaPhoneAlt style={{ color: "red" }} />
                      <span style={{ color: "#5b5b5b" }}>{item?.mobile}</span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <IoMdPricetag style={{ color: "red" }} />
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {formatCurrencyToK(item?.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
