import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../features/post/postSlice";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Exchange = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPost());
  }, []);
  const postGL = useSelector((state) => state.post.post.Posts);
  const filterGiaoLuu = postGL.filter((item) => item.type === "Giao lưu");
  const isVideo = (url) => {
    return url && url.includes("video");
  };
  return (
    <Row className="p-4">
      <div className="btn-none mb-3">
        <Link to="/home/giaoluu/post">
          <button
            className="btn btn-danger "
            style={{
              borderRadius: "50px",
              width: "190px",

              height: "47px",
            }}
          >
            Đăng tuyển giao lưu
          </button>
        </Link>
      </div>
      <h3 className="text-center fw-bold">TUYỂN GIAO LƯU</h3>
      <strong>Tìm thấy {filterGiaoLuu?.length} hoạt động</strong>
      <div
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
          display: "grid",
          gap: "20px",
          marginTop: "30px",
        }}
      >
        {filterGiaoLuu?.map((item) => (
          <div
            key={item?._id}
            style={{
              padding: "10px",
              overflow: "hidden",
              borderRadius: "20px",
              cursor: "pointer",
              background: "white",
              boxShadow: "#ccc 1px 1px 10px",
            }}
          >
            <div className="row ">
              <div className="col-6">
                <Link to={`/badminton/${item._id}`}>
                  {item?.images && item.images[0]?.url ? (
                    isVideo(item.images[0].url) ? (
                      <video
                        src={item.images[0].url}
                        alt="video preview"
                        style={{
                          width: "100%",
                          height: "166px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="media-mobile-cours"
                        controls
                      />
                    ) : (
                      <img
                        src={item.images[0].url}
                        alt="image preview"
                        style={{
                          width: "100%",
                          height: "166px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                    )
                  ) : (
                    <p>No media available</p>
                  )}
                </Link>
              </div>
              <div className="col-6 d-flex flex-column">
                <Link
                  to={`/badminton/${item._id}`}
                  style={{ textDecoration: "none !important" }}
                >
                  <span className="fw-bold">{item?.title}</span>
                </Link>
                <span className="fw-bold">
                  <FaMapMarkerAlt /> {item?.san},{item?.quan},{item?.city}
                </span>
                <p className="text-danger">
                  Người cho thuê: {item?.updateBy?.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Row>
  );
};

export default Exchange;
