import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterPost, resetPost, getAllPost } from "../features/post/postSlice";
import { Select, DatePicker, Button } from "antd";
import moment from "moment";

const { Option } = Select;

const Filter2 = () => {
  const dispatch = useDispatch();
  const statePosts = useSelector((state) => state?.post?.post.Posts);

  const [filters, setFilters] = useState({
    city: undefined,
    quan: undefined,
    san: undefined,
    date: undefined,
    timeStart: undefined,
    timeEnd: undefined,
    minTrinhDo: undefined,
    maxTrinhDo: undefined,
    pricenu: undefined,
    pricenam: undefined,
    namnu: undefined,
  });

  const [uniqueQuans, setUniqueQuans] = useState([]);
  const [uniqueSans, setUniqueSans] = useState([]);

  const [selectedQuan, setSelectedQuan] = useState(null);
  const [selectedSan, setSelectedSan] = useState(null);

  useEffect(() => {
    dispatch(getAllPost());
  }, [dispatch]);

  useEffect(() => {
    if (!statePosts) return;

    const quanSet = new Set();
    const sanSet = new Set();

    statePosts.forEach((post) => {
      quanSet.add(post.quan);
      sanSet.add(post.san);
    });

    const uniqueQuans = Array.from(quanSet);
    const uniqueSans = Array.from(sanSet);

    setUniqueQuans(uniqueQuans);
    setUniqueSans(uniqueSans);

    setSelectedQuan(uniqueQuans.includes(filters.quan) ? filters.quan : null);
    setSelectedSan(uniqueSans.includes(filters.san) ? filters.san : null);
  }, [statePosts, filters.quan, filters.san]);

  const handleChange = (value, field) => {
    let newValue = value;
    if (field === "date") {
      newValue = value ? value.format("YYYY-MM-DD") : undefined;
    } else if (field === "timeStart" || field === "timeEnd") {
      newValue = value ? value.format("HH:mm:ss") : undefined;
    }

    const newFilters = {
      ...filters,
      [field]: newValue,
    };

    setFilters(newFilters);
    dispatch(filterPost(newFilters));
  };

  const handleReset = () => {
    setFilters({
      city: undefined,
      quan: undefined,
      san: undefined,
      date: undefined,
      timeStart: undefined,
      timeEnd: undefined,
      minTrinhDo: undefined,
      maxTrinhDo: undefined,
      pricenu: undefined,
      pricenam: undefined,
      namnu: undefined,
    });
    dispatch(resetPost());

  };

  

  return (
    <div
      className="sc d-flex gap-3 pt-3 align-items-center"
      style={{
        width: "60%",
        position: "relative",
      }}
    >
      <div>
        <Select
          placeholder="Thành phố"
          style={{
            width: "max-content",
          }}
          onChange={(value) => handleChange(value, "city")}
          options={[
            {
              value: "Thành phố Hồ Chí Minh",
              label: "Thành phố Hồ Chí Minh",
            },
            {
              value: "Thành phố Hà Nội",
              label: "Thành phố Hà Nội",
            },
            {
              value: "Các tỉnh khác",
              label: "Các tỉnh khác",
            },
          ]}
          dropdownStyle={{
            zIndex: 9999999999,
            borderRadius: "20px",
          }}
          defaultValue={filters.city}
          key={filters.city}
        />
      </div>
      <div>
        <Select
          placeholder="Quận"
          style={{
            width: "max-content",
          }}
          onChange={(value) => handleChange(value, "quan")}
          value={filters.quan}
          key={filters.quan}
        >
          {uniqueQuans.map((quan) => (
            <Option key={quan} value={quan}>
              {quan}
            </Option>
          ))}
        </Select>
      </div>
      <div
        style={{
          // width: "100%",
          transform: "translateY(9px)",
        }}
      >
        <DatePicker
          placeholder="Ngày"
          value={filters.date ? moment(filters.date, "YYYY-MM-DD") : undefined}
          onChange={(date) => handleChange(date, "date")}
          style={{
            width: "150px",
            padding: "13px",
          }}
        />
      </div>
      <div>
        <Select
          placeholder="Sân"
          style={{
            width: "max-content",
            borderRadius: "10px",
          }}
          onChange={(value) => handleChange(value, "san")}
          value={filters.san}
          key={filters.san}
        >
          {uniqueSans.map((san) => (
            <Option key={san} value={san}>
              {san}
            </Option>
          ))}
        </Select>
      </div>
      {/* <div>
        <Select
          placeholder="Giới tính"
          style={{
            width: "max-content",
            borderRadius: "10px",
          }}
          onChange={(value) =>
            handleChange(value, "namnu")
          }
          value={filters.namnu}
          key={filters.namnu}
          options={[
            {
              value: "Cả nam và nữ",
              label: "Cả nam và nữ",
            },
            {
              value: "Chỉ nam",
              label: "Chỉ nam",
            },
            {
              value: "Chỉ nữ",
              label: "Chỉ nữ",
            },
          ]}
        />
      </div>
      <div>
        <Select
          placeholder="Trình độ"
          style={{
            width: "max-content",
            borderRadius: "10px",
          }}
          onChange={(value) =>
            handleChange(
              value,
              "minTrinhDo"
            )
          }
          value={filters.minTrinhDo}
          key={filters.minTrinhDo}
          options={[
            {
              value: "Yếu",
              label: "Yếu",
            },
            {
              value: "TBY",
              label: "TBY",
            },
            {
              value: "TB-",
              label: "TB-",
            },
            {
              value: "TB",
              label: "TB",
            },
            {
              value: "TB+",
              label: "TB+",
            },
            {
              value: "TB++",
              label: "TB++",
            },
            {
              value: "TBK",
              label: "TBK",
            },
            {
              value: "Khá",
              label: "Khá",
            },
          ]}
        />
      </div>
      <div>
        <Select
          placeholder="Giá (Nam)"
          style={{
            width: "max-content",
            borderRadius: "10px",
          }}
          onChange={(value) =>
            handleChange(
              value,
              "pricenu"
            )
          }
          value={filters.pricenu}
          key={filters.pricenu}
          options={[
            {
              value: "<50000",
              label: "<50,000",
            },
            {
              value: "50000-200000",
              label: "50,000 - 200,000",
            },
            {
              value: "200000-500000",
              label:
                "200,000 - 500,000",
            },
          ]}
        />
      </div> */}
      <div>
        <Select
          placeholder="Giá thuê sân"
          style={{
            width: "max-content",
            borderRadius: "10px",
          }}
          onChange={(value) => handleChange(value, "pricenam")}
          value={filters.pricenam}
          key={filters.pricenam}
          options={[
            {
              value: "<50000",
              label: "<50,000",
            },
            {
              value: "50000-200000",
              label: "50,000 - 200,000",
            },
            {
              value: "200000-500000",
              label: "200,000 - 500,000",
            },
          ]}
        />
      </div>
      <div>
        <Button
          onClick={handleReset}
          style={{
            borderRadius: "30px",
            transform: "translateY(7px)",
            padding: "25px",
          }}
        >
          Xóa lọc
        </Button>
      </div>
    </div>
  );
};

export default Filter2;
