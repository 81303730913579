import {
  createSlice,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import { thuesanService } from "./thuesanService";
export const getAllThueSan =
  createAsyncThunk(
    "thuesan/getAllThueSan",
    async (thunkApi) => {
      try {
        return await thuesanService.getAllThueSan();
      } catch (error) {
        return thunkApi.rejectWithValue(
          error
        );
      }
    }
  );
export const getThueSan =
  createAsyncThunk(
    "thuesan/getThueSan",
    async (id, thunkApi) => {
      try {
        return await thuesanService.getThueSan(
          id
        );
      } catch (error) {
        return thunkApi.rejectWithValue(
          error
        );
      }
    }
  );
export const resetState = createAction(
  "Reset_all"
);

const initialState = {
  thuesan: [],
  addThueSan: "",
  getThueSan: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const thuesanSlice = createSlice(
  {
    name: "thuesan",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(
          getAllThueSan.pending,
          (state) => {
            state.isLoading = true;
          }
        )
        .addCase(
          getAllThueSan.fulfilled,
          (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.thuesan =
              action.payload;
          }
        )
        .addCase(
          getAllThueSan.rejected,
          (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message =
              action.error;
            state.thuesan = null;
          }
        )

        .addCase(
          getThueSan.pending,
          (state) => {
            state.isLoading = true;
          }
        )
        .addCase(
          getThueSan.fulfilled,
          (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.getThueSan =
              action.payload;
          }
        )
        .addCase(
          getThueSan.rejected,
          (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.message =
              action.error;
          }
        )
        .addCase(
          resetState,
          () => initialState
        );
    },
  }
);

export default thuesanSlice.reducer;
