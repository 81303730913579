import React, { useState, useEffect, useRef } from "react";
import { FaSearch, FaRegUserCircle, FaMapMarkerAlt } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";
import { Switch, Dropdown, Space, Modal, Input, Button } from "antd";
import { Col, Row } from "react-bootstrap";
import icon from "../images/icon.png";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiAbstract106 } from "react-icons/gi";
import { useFormik } from "formik";
import * as Yup from "yup";
import Filter1 from "./Filter1";
import Filter2 from "./Filter2";
import Filter3 from "./Filter3";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../features/users/userSlice";
import Login from "./Login";
import { Select } from "antd";
import { getAllPost } from "../features/post/postSlice";
import { filterPost } from "../features/post/postSlice";
import { option3 } from "../data/data";
const Header = ({ onFilterChange, checked, onCheckedChange }) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const updateCheck = () => {
      if (window.innerWidth <= 768) {
        setCheck(true);
      } else {
        setCheck(false);
      }
    };
    updateCheck();
    window.addEventListener("resize", updateCheck);
    return () => {
      window.removeEventListener("resize", updateCheck);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const idslug = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.auth.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [activeComponent, setActiveComponent] = useState("Filter2");
  const [name, setName] = useState("Giao lưu");
  const [toggle, setToggle] = useState(false);
  const menuRef = useRef(null);
  const idUser = userState?._id;
  const [cfpassword, setCfpassword] = useState("");
  const [filters, setFilters] = useState({
    city: null,
  });

  const handleChange = (value, field) => {
    let newValue = value;

    const newFilters = {
      ...filters,
      [field]: newValue,
    };

    setFilters(newFilters);
    dispatch(filterPost(newFilters));
  };
  const dropdownContainerRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const removeAccentsAndSpaces = (str) => {
    return str
      .normalize("NFD") // Tách các ký tự có dấu
      .replace(/[\u0300-\u036f]/g, "") // Xóa dấu
      .replace(/đ/g, "d") // Chuyển đổi "đ" thành "d"
      .replace(/Đ/g, "D") // Chuyển đổi "Đ" thành "D"
      .toLowerCase() // Chuyển thành chữ thường
      .replace(/\s+/g, ""); // Xóa khoảng trắng
  };
  const handleNavigatePage = () => {
    navigate(`/home/${removeAccentsAndSpaces(name)}/post`);
  };
  const handleNavigatePage2 = () => {
    navigate(`/home/traodoi/post-product`);
  };
  const a =
    userState?.role === "Chủ sân"
      ? [
          {
            label: (
              <span
                onClick={() => navigate(`/quan-ly-thue-san/${idUser}`)}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Quản lý thuê sân
              </span>
            ),
            key: "1",
          },
          {
            label: (
              <span
                onClick={() => navigate(`/quan-ly-san-pham`)}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Quản lý sản phẩm
              </span>
            ),
            key: "7",
          },
          {
            label: (
              <span
                onClick={() => navigate("/tao-san")}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Nhập sân
              </span>
            ),
            key: "8",
          },
        ]
      : [];
  const items =
    userState === null
      ? [
          {
            label: (
              <span
                onClick={() => handleMenuClick("register")}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Đăng ký
              </span>
            ),
            key: "0",
          },
          {
            label: (
              <span
                onClick={() => handleMenuClick("login")}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Đăng nhập
              </span>
            ),
            key: "1",
          },
        ]
      : [
          {
            label: (
              <span
                onClick={() => navigate("/mypost")}
                style={{
                  display: "block",
                  width: "100%",
                }}
              >
                Bài đăng
              </span>
            ),
            key: "2",
          },

          ...a,
          // {
          //   label: (
          //     <span
          //       onClick={() => navigate(`/quan-ly-thue-san/${idUser}`)}
          //       style={{
          //         display: "block",
          //         width: "100%",
          //       }}
          //     >
          //       Quản lý thuê sân
          //     </span>
          //   ),
          //   key: "1",
          // },
          // {
          //   label: (
          //     <span
          //       onClick={() => navigate(`/quan-ly-san-pham`)}
          //       style={{
          //         display: "block",
          //         width: "100%",
          //       }}
          //     >
          //       Quản lý sản phẩm
          //     </span>
          //   ),
          //   key: "7",
          // },
        ];

  if (isMobile) {
    items.push(
      {
        label: (
          <div className="">
            <div onClick={handleNavigatePage} className="text-black">
              <span>Đăng tin</span>
            </div>
          </div>
        ),
        key: "3",
      },
      {
        label: (
          <div className="">
            <Link to="/home/traodoi/post-product" className="text-black">
              <span>Đăng sản phẩm</span>
            </Link>
          </div>
        ),
        key: "4",
      },
      {
        label: (
          <span
            onClick={() => navigate("/tao-san")}
            style={{
              display: "block",
              width: "100%",
            }}
          >
            Nhập sân
          </span>
        ),
        key: "8",
      },
      {
        label: (
          <>
            {name === "Trao đổi" ? (
              <div className="">
                <Link to="/home/traodoi/mua-ban-traodoi" className="text-black">
                  <span>Mua, bán & trao đổi</span>
                </Link>
              </div>
            ) : name === "Sân đấu" ? (
              <div className="">
                <Link to="/home/sandau/thue-san" className="text-black">
                  <span>Cho thuê & Pass sân</span>
                </Link>
              </div>
            ) : (
              <div className="">
                <Link to="/home/giaoluu/tuyen-giao-luu" className="text-black">
                  <span>Tuyển giao lưu</span>
                </Link>
              </div>
            )}
          </>
        ),
        key: "5",
      }
    );
  }

  if (userState !== null) {
    items.push({
      label: (
        <span
          onClick={() => {
            sessionStorage.removeItem("user");
            window.location.reload();
          }}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          Đăng xuất
        </span>
      ),
      key: "6",
    });
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedActiveComponent = localStorage.getItem("activeComponent");
    const savedName = localStorage.getItem("activeName");
    if (savedActiveComponent && savedName) {
      setActiveComponent(savedActiveComponent);
      setName(savedName);
    }
  }, []);

  useEffect(() => {
    if (idslug === "giaoluu") {
      setActiveComponent("Filter2");
      setName("Giao lưu");
    } else if (idslug === "sandau") {
      setActiveComponent("Filter3");
      setName("Sân đấu");
    } else if (idslug === "traodoi") {
      setActiveComponent("Filter1");
      setName("Trao đổi");
    }
  }, [idslug]);

  const click1 = () => {
    setName("Giao lưu");
    setActiveComponent("Filter2");
    setToggle(false);
  };

  const click2 = () => {
    setActiveComponent("Filter3");
    setName("Sân đấu");
    setToggle(false);
  };

  const click3 = () => {
    setActiveComponent("Filter1");
    setName("Trao đổi");
    setToggle(false);
  };

  const handleMenuClick = (type) => {
    setModalType(type);
    setIsModalVisible(true);
    formik.resetForm();
  };

  const onChange = (checked) => {
    onCheckedChange(checked);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalType(null);
    formik.resetForm();
  };

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email should be valid")
      .required("Yêu cầu nhập Email"),
    name: Yup.string().required("Yêu cầu nhập tên"),
    mobile: Yup.string().required("Yêu cầu nhập số điện thoại"),
    address: Yup.string().required("Yêu cầu nhập địa chỉ"),
    password: Yup.string().required("Yêu cầu nhập mật khẩu"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      mobile: "",
      address: "",
      password: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (modalType === "register") {
        if (values.password === cfpassword) {
          dispatch(register(values));
          setTimeout(() => {
            formik.resetForm();
            setCfpassword("");
          }, 300);
        } else {
          toast.warning("Mật khẩu không trùng khớp");
        }
      }
    },
  });

  useEffect(() => {
    localStorage.setItem("activeComponent", activeComponent);
    localStorage.setItem("activeName", name);
  }, [activeComponent, name]);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          width: "100%",
          padding: "20px 30px",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          background: "white",
          boxShadow: "silver 0px 5px 10px",
        }}
        className="header"
      >
        <div
          className={`d-flex align-items-center  ${
            check === true ? "" : "justify-content-between"
          } flex-mobile`}
        >
          <div className="d-flex justify-content-between ">
            <Link to="/" className="img">
              <div
                style={{
                  color: "white",
                }}
                className="d-flex gap-2"
              >
                <span className="text-black fs-4">GiaoLưuCầuLông</span>
                <img
                  src={icon}
                  style={{
                    width: "40px",
                  }}
                  alt="icon"
                />
              </div>
            </Link>
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center width-mobile"
            style={{
              height: "100%",
            }}
          >
            <div className="acbcc d-flex align-items-center justify-content-center gap-2">
              <div
                className="input-mobile"
                style={{
                  position: "relative",

                  background: "white",
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 10px",
                  borderRadius: "50px",
                  border: "none",
                  height: "50px",
                  outline: "none",
                  width: "400px",
                  justifyContent: "space-between",
                  boxShadow: "#ccc 1px 1px 10px",
                }}
              >
                <div className="bnm">
                  <Select
                    placeholder="Thành phố"
                    style={{
                      width: "max-content",
                    }}
                    onChange={(value) => handleChange(value, "city")}
                    options={[
                      {
                        value: "Thành phố Hồ Chí Minh",
                        label: "Hồ Chí Minh",
                      },
                      {
                        value: "Thành phố Hà Nội",
                        label: "Hà Nội",
                      },
                      {
                        value: "Các tỉnh khác",
                        label: "Các tỉnh khác",
                      },
                    ]}
                    value={filters.city || "Thành phố Hà Nội"}
                    className="abcccccc"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    borderLeft: "2px solid silver",
                    paddingLeft: "20px",
                  }}
                  className=" d-flex v"
                >
                  <span
                    className="dt-mobile"
                    style={{
                      display: "inline-block",
                    }}
                    onClick={() => setToggle(true)}
                  >
                    {name}
                  </span>
                </div>
                {toggle && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      bottom: "-370px",
                      zIndex: 9999999999,
                      background: "white",
                      borderRadius: "20px",
                      left: "0px",
                      right: "0px",
                      padding: "20px",
                      boxShadow: "#ccc 1px 1px 10px",
                    }}
                    className="menu-toggle"
                  >
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fs-4 fw-bold">Loại</span>
                        <span
                          className="close fs-4"
                          onClick={() => setToggle(false)}
                        >
                          <IoCloseSharp />
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/home/giaoluu");
                          dispatch(getAllPost());
                        }}
                      >
                        <div
                          className={`d-flex align-items-center gap-4 text-dark ${
                            idslug === "giaoluu" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                            padding: "15px",
                            borderRadius: "15px",
                          }}
                          onClick={click1}
                        >
                          <FaMapMarkerAlt />
                          <div className="d-flex flex-column justify-content-center">
                            <span>Giao lưu</span>
                            <span>Tìm ca giao lưu cầu lông gần bạn</span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/home/sandau");
                          dispatch(getAllPost());
                        }}
                      >
                        <div
                          className={`d-flex align-items-center gap-4 text-dark ${
                            idslug === "sandau" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                            padding: "15px",
                            borderRadius: "15px",
                          }}
                          onClick={click2}
                        >
                          <Gi3dStairs />
                          <div className="d-flex flex-column justify-content-center">
                            <span>Sân đấu</span>
                            <span>Tìm sân cầu lông gần bạn</span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/home/traodoi");
                          dispatch(getAllPost());
                        }}
                      >
                        <div
                          className={`d-flex align-items-center gap-4 text-dark ${
                            idslug === "traodoi" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                            padding: "15px",
                            borderRadius: "15px",
                          }}
                          onClick={click3}
                        >
                          <GiAbstract106 />
                          <div className="d-flex flex-column justify-content-center">
                            <span>Trao đổi</span>
                            <span>Bạn muốn trao đổi</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    background: "red",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                  }}
                  className="d-flex justify-content-center align-items-center fs-5 text-white"
                >
                  <FaSearch />
                </div>
              </div>
              {/* 
              <div onClick={handleNavigatePage} className="btn-none">
                <div>
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "160px",
                      height: "47px",
                    }}
                  >
                    Đăng tin
                  </button>
                </div>
              </div> */}

              <div className="btn-none">
                <Link to="/home/traodoi/mua-ban-traodoi">
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "190px",

                      height: "47px",
                    }}
                  >
                    Mua, Bán & Trao đổi
                  </button>
                </Link>
              </div>
              {/* <div onClick={handleNavigatePage} className="btn-none">
                <div>
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "160px",
                      height: "47px",
                    }}
                  >
                    Đăng tin
                  </button>
                </div>
              </div> */}
              {/* <div className="btn-none">
                    <Link to="/home/traodoi/post-product">
                      <button
                        className="btn btn-danger "
                        style={{
                          borderRadius: "50px",
                          width: "160px",
                          height: "47px",
                        }}
                      >
                        Đăng sản phẩm
                      </button>
                    </Link>
                  </div> */}

              <div className="btn-none">
                <Link to="/home/sandau/thue-san">
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "190px",
                      height: "47px",
                    }}
                  >
                    Cho Thuê & Pass Sân
                  </button>
                </Link>
              </div>
              {/* <div onClick={handleNavigatePage} className="btn-none">
                <div>
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "160px",
                      height: "47px",
                    }}
                  >
                    Đăng tin
                  </button>
                </div>
              </div> */}
              {/* <div className="btn-none">
                <Link to="/home/traodoi/post-product">
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "160px",
                      height: "47px",
                    }}
                  >
                    Đăng sản phẩm
                  </button>
                </Link>
              </div> */}
              <div className="btn-none">
                <Link to="/home/giaoluu/tuyen-giao-luu">
                  <button
                    className="btn btn-danger "
                    style={{
                      borderRadius: "50px",
                      width: "190px",
                      height: "47px",
                    }}
                  >
                    Tuyển giao lưu
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="map-mobile d-flex gap-2 align-items-center justify-content-end">
            {/* <div className="d-flex align-items-center gap-3">
              <span className="on-map" style={{ fontSize: "16px" }}>
                Bảng đồ
              </span>
              <Switch checked={checked} onChange={onChange} />
            </div> */}
            <div>
              <Dropdown
                menu={{ items }}
                getPopupContainer={() => dropdownContainerRef.current}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space
                    style={{
                      border: "1px solid black",
                      padding: "9px 20px",
                      borderRadius: "50px",
                      display: "flex",
                      color: "black",
                      alignItems: "center",
                    }}
                    className="space-mobile"
                  >
                    <FaRegUserCircle className="fs-4" />
                    <span className="name-mobile">
                      {userState !== null ? userState?.name : "Tài khoản"}
                    </span>
                  </Space>
                </a>
              </Dropdown>
              <div
                ref={dropdownContainerRef}
                style={{
                  zIndex: 9999999999999,
                }}
              />
              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
                style={{
                  top: 0,
                  zIndex: 10000,
                }}
              >
                <form
                  style={{
                    zIndex: 100000000000,
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  {modalType === "register" && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-item-center">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img src={icon} width={70} alt="" />
                        </div>
                        <span className="text-center fs-2 fw-bold mt-2">
                          Đăng ký tài khoản mới
                        </span>
                        <p className="text-center">
                          Đã có tài khoản ?{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                            onClick={() => handleMenuClick("login")}
                          >
                            Đăng nhập
                          </span>
                        </p>
                      </div>
                      <div className="form-group">
                        <Input
                          name="email"
                          className="p-3 mb-3"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Input
                          name="name"
                          className="p-3 mb-3"
                          placeholder="Nhập tên"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Input
                          name="mobile"
                          className="p-3 mb-3"
                          placeholder="Nhập số điện thoại"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.mobile && formik.touched.mobile && (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Input
                          name="address"
                          className="p-3 mb-3"
                          placeholder="Nhập địa chỉ"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.address && formik.touched.address && (
                          <div className="text-danger">
                            {formik.errors.address}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Input.Password
                          name="password"
                          className="p-3 mb-3"
                          placeholder="Password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.password && formik.touched.password && (
                          <div className="text-danger">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <Input.Password
                          name="cfpassword"
                          className="p-3 mb-3"
                          placeholder="Confirm Password"
                          value={cfpassword}
                          onChange={(e) => setCfpassword(e.target.value)}
                        />
                      </div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                        }}
                        className="p-4"
                      >
                        {modalType === "register" ? "Đăng ký" : "Đăng nhập"}
                      </Button>
                    </>
                  )}
                </form>
                {modalType === "login" && (
                  <Login handleMenuClick={handleMenuClick} />
                )}
              </Modal>
            </div>
          </div>
        </div>

        {location.pathname !== "/post" &&
          location.pathname !== "/post-product" && (
            <>
              {activeComponent === "Filter1" && <Filter1 />}
              {activeComponent === "Filter2" && <Filter2 />}
              {activeComponent === "Filter3" && (
                <Filter3 onFilterChange={onFilterChange} />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default Header;
