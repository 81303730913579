import React, {
  useState,
  useEffect,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype
  ._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const SetViewOnClick = ({
  coords,
  zoom,
}) => {
  const map = useMap();
  map.setView(coords, zoom);
  return null;
};

const Map = ({ locations }) => {
  const [zoom, setZoom] = useState(13);
  const [error, setError] =
    useState(null);
  const [markers, setMarkers] =
    useState([]);

  useEffect(() => {
    const geocodeLocations =
      async () => {
        try {
          const promises =
            locations.map(
              async (location) => {
                console.log(
                  `Fetching data for location: ${location}`
                );
                const response =
                  await fetch(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
                      location
                    )}`
                  );
                if (!response.ok) {
                  throw new Error(
                    "Network response was not ok"
                  );
                }
                const data =
                  await response.json();
                console.log(
                  `Received data: `,
                  data
                );
                if (data.length > 0) {
                  const { lat, lon } =
                    data[0];
                  return {
                    position: [
                      parseFloat(lat),
                      parseFloat(lon),
                    ],
                    name: location,
                  };
                } else {
                  throw new Error(
                    "Location not found"
                  );
                }
              }
            );
          const resolvedMarkers =
            await Promise.all(promises);
          setMarkers(resolvedMarkers);
          setZoom(12); // Adjust zoom level as needed
        } catch (error) {
          console.error(
            "Failed to fetch location data:",
            error
          );
          setError(
            "Failed to fetch location data"
          );
        }
      };
    geocodeLocations();
  }, [locations]);

  return (
    <div
      style={{ zIndex: 1 }}
      className="war-mobile">
      {error && (
        <div style={{ color: "red" }}>
          {error}
        </div>
      )}
      <MapContainer
        center={[21.0285, 105.8008]} // Default center, could be adjusted based on markers
        zoom={zoom}
        style={{
          height: "450px",
          width: "100%",
        }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map(
          (marker, index) => (
            <Marker
              key={index}
              position={
                marker.position
              }>
              <Popup>
                {marker.name}
              </Popup>
            </Marker>
          )
        )}
        <SetViewOnClick
          coords={[21.0285, 105.8008]}
          zoom={zoom}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
