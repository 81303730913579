import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import { Select } from "antd";
import { Container } from "react-bootstrap";

export default function MyProduct() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sortOrder, setSortOrder] = useState("Thấp đến cao");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const formatCurrencyToK = (amount) => {
    const formatter = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    });

    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/\s*₫/, "Đ");

    return formattedAmount;
  };
  const allProduct = useSelector((state) => state.product.product.products);
  const user = useSelector((state) => state.auth.user);
  const filter = allProduct?.filter((e) => e.updateBy === user?._id);
  const displayProducts = filter?.length > 0 ? filter : allProduct;
  const sortedProducts =
    displayProducts &&
    [...displayProducts].sort((a, b) => {
      if (sortOrder === "Thấp đến cao") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    });
  const handleChange = (value) => {
    console.log(value);
    setSortOrder(value);
  };
  console.log("filter", filter);
  return (
    <>
      <Container>
        <div className="d-flex align-items-center py-4 gap-3 flex-wrap">
          <label>Sắp xếp</label>
          <Select
            defaultValue="Thấp đến cao"
            placeholder="Sắp xếp"
            style={{ width: 200 }}
            onChange={handleChange}
            options={[
              {
                label: "Thấp đến cao",
                value: "Thấp đến cao",
              },
              {
                label: "Cao đến thấp",
                value: "Cao đến thấp",
              },
            ]}
          />
        </div>
      </Container>
      {isMobile ? (
        <div
          className="d-flex flex-wrap my-5 gap-5 position-mobile"
          style={{ width: "100%" }}
        >
          {filter &&
            sortedProducts.map((item) => (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  boxShadow: "#ccc 1px 1px 10px",
                }}
                className="bnn"
                key={item?._id}
              >
                <div className="d-flex gap-3 flex-column jkl">
                  <Link to={`/product/${item?._id}`}>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={item?.images[0]?.url}
                        alt=""
                        style={{
                          width: "100%",
                          height: "200px !important",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          borderRadius: "20px",
                          background: "#f5002f",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="btn btn-danger"
                      >
                        {formatCurrencyToK(item.price)}
                      </button>
                    </div>
                  </Link>
                  <div className="d-flex flex-column justify-content-evenly">
                    <Link to={`/product/${item?._id}`}>
                      <h4 className="text-dark">{item?.title}</h4>
                    </Link>
                    <div className="d-flex gap-3 align-items-center">
                      <FaMapMarkerAlt
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "#5b5b5b",
                        }}
                      >
                        Tại {item?.city}
                      </span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <FaPhoneAlt
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "#5b5b5b",
                        }}
                      >
                        {item?.mobile}
                      </span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <IoMdPricetag
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {formatCurrencyToK(item?.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div
          className="d-flex p-4"
          style={{
            width: "100%",
            flexWrap: "wrap",
            justifyContent:'space-between',
            gap: "25px",
          }}
        >
          {sortedProducts &&
            sortedProducts.map((item) => (
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  borderRadius: "20px",
                  marginBottom: "5px",
                  width: "23%",
                  boxShadow: "#ccc 1px 1px 10px",
                }}
                className="bnn"
                key={item?._id}
              >
                <div className="d-flex gap-3 flex-column jkl">
                  <Link to={`/product/${item?._id}`}>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={item?.images[0]?.url}
                        alt=""
                        style={{
                          width: "100%",
                          height: "166px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                          borderRadius: "20px",
                          background: "#f5002f",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="btn btn-danger"
                      >
                        {formatCurrencyToK(item.price)}
                      </button>
                    </div>
                  </Link>
                  <div className="d-flex flex-column justify-content-evenly">
                    <Link to={`/product/${item?._id}`}>
                      <h4 className="text-dark">{item?.title}</h4>
                    </Link>
                    <div className="d-flex gap-3 align-items-center">
                      <FaMapMarkerAlt
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "#5b5b5b",
                        }}
                      >
                        Tại {item?.city}
                      </span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <FaPhoneAlt
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "#5b5b5b",
                        }}
                      >
                        {item?.mobile}
                      </span>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <IoMdPricetag
                        style={{
                          color: "red",
                        }}
                      />
                      <span
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {formatCurrencyToK(item?.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
}
