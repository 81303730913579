import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import pLimit from 'p-limit';

const Map2 = ({ dataMap }) => {
  const data = dataMap?.filter((item) => item.type !== "Trao đổi") || [];
  const [locations, setLocations] = useState([]);
  const cache = {}; // Bộ nhớ cache cho kết quả tọa độ

  const limit = pLimit(5); // Giới hạn số lượng request đồng thời

  const getCoordinates = async (address, controller) => {
    if (cache[address]) {
      return cache[address]; // Trả về từ cache nếu đã có
    }

    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`;

    try {
      const response = await axios.get(url, { signal: controller.signal });
      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        const coordinates = { lat: parseFloat(lat), lon: parseFloat(lon) };
        cache[address] = coordinates; // Lưu kết quả vào cache
        return coordinates;
      } else {
        console.error("Không tìm thấy tọa độ cho địa chỉ:", address);
        return null;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request bị hủy:", error.message);
      } else {
        console.error("Lỗi khi lấy tọa độ:", error);
      }
      return null;
    }
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      const controllers = data.map(() => new AbortController());
      const requests = data.map((location, index) => 
        limit(async () => {
          const address = `${location.san}, ${location.quan}, ${location.city}`;
          const coordinates = await getCoordinates(address, controllers[index]);
          return { ...location, coordinates };
        })
      );

      let completed = false;

      try {
        const results = await Promise.race([
          ...requests,
          new Promise((resolve) => {
            setTimeout(() => resolve([]), 10000); // Giới hạn thời gian tìm kiếm
          })
        ]);

        if (results.length > 0) {
          completed = true;
          setLocations(results.filter((location) => location.coordinates !== null));
          controllers.forEach((controller) => controller.abort()); // Hủy tất cả các request còn lại
        }
      } catch (error) {
        console.error("Lỗi khi thực hiện request:", error);
      }

      if (!completed) {
        // Nếu không có kết quả, chỉ cần lọc các kết quả không có tọa độ
        const results = await Promise.all(requests);
        setLocations(results.filter((location) => location.coordinates !== null));
      }
    };

    fetchCoordinates();
  }, [data]);

  return (
    <div>
      {locations.length === 0 ? (
        <p>Vui lòng chờ...</p>
      ) : (
        <MapContainer
          center={[21.0285, 105.8542]}
          zoom={13}
          style={{ height: "70vh", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={[location.coordinates.lat, location.coordinates.lon]}
            >
              <Popup>
                {location.san}, {location.quan}, {location.city}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    </div>
  );
};

export default Map2;
