import axios from "axios";
import {base_url} from '../../utils/base_url'
import {config} from '../../utils/axiosconfig';

const getquan = async()=>{
    const res = await axios.get(`${base_url}quan/get-all-quan`)
    return res.data
}
const createquan =async(quan)=>{
    const res = await axios.post(`${base_url}quan/add-quan`,quan,config)
    return res.data
}

const getIdquan =async(id)=>{
    const res = await axios.get(`${base_url}quan/get-quan/${id}`,config)
    return res.data
}

const updatequan =async(quan)=>{
    const res = await axios.put(`${base_url}quan/update-quan/${quan?._id}`,{images:quan?.quanData?.images},config)
    return res.data
}

const deletequan =async(id)=>{
    const res = await axios.delete(`${base_url}quan/delete-quan/${id}`,config)
    return res.data
}

const quanService={
    getquan,
    createquan,
    getIdquan,updatequan,deletequan
}

export default quanService