export const options = [
  { value: "Yếu", label: "Yếu" },
  { value: "TBY", label: "TBY" },
  { value: "TB-", label: "TB-" },
  { value: "TB", label: "TB" },
  { value: "TB+", label: "TB+" },
  { value: "TB++", label: "TB++" },
  { value: "TBK", label: "TBK" },
  { value: "Khá", label: "Khá" },
  {
    value: "Chuyên nghiệp",
    label: "Chuyên nghiệp",
  },
];

export const option1 = [
  {
    value: "Cả nam và nữ",
    label: "Cả nam và nữ",
  },
  {
    value: "Chỉ nam",
    label: "Chỉ nam",
  },
  { value: "Chỉ nữ", label: "Chỉ nữ" },
];
export const option2 = [
  {
    value: "Hằng ngày",
    label: "Hằng ngày",
  },
  {
    value: "Hằng tuần",
    label: "Hằng tuần",
  },
];
export const option3 = [
  {
    value: "Trao đổi",
    label: "Trao đổi",
  },
  {
    value: "Sân đấu",
    label: "Sân đấu",
  },
  {
    value: "Giao lưu",
    label: "Giao lưu",
  },
];
export const option4 = [
  {
    value: "Thành phố Hồ Chí Minh",
    label: "Thành phố Hồ Chí Minh",
  },
  {
    value: "Thành phố Hà Nội",
    label: "Thành phố Hà Nội",
  },
  {
    value: "Các tỉnh khác",
    label: "Các tỉnh khác",
  },
];
export const option5 = [
  {
    value: "Quận Ba Đình",
    label: "Quận Ba Đình",
  },
  {
    value: "Quận Tây Hồ",
    label: "Quận Tây Hồ",
  },
  {
    value: "Quận Long Biên",
    label: "Quận Long Biên",
  },
  {
    value: "Quận Đống Đa",
    label: "Quận Đống Đa",
  },
  {
    value: "Quận Hai Bà Trưng",
    label: "Quận Hai Bà Trưng",
  },
];
export const option6 = [
  {
    value: "Sân cầu lông Yên Hòa",
    label: "Sân cầu lông Yên Hòa",
  },
  {
    value: "Sân 521 Minh Khai",
    label: "Sân 521 Minh Khai",
  },
  {
    value: "Sân Đức Thảo",
    label: "Sân Đức Thảo",
  },
];
export const dataTime = [
  { time: "5:30" },
  { time: "6:00" },
  { time: "6:30" },
  { time: "7:00" },
  { time: "7:30" },
  { time: "8:00" },
  { time: "8:30" },
  { time: "9:00" },
  { time: "9:30" },
  { time: "10:00" },
  { time: "10:30" },
  { time: "11:00" },
  { time: "11:30" },
  { time: "12:00" },
  { time: "12:30" },
  { time: "13:00" },
  { time: "13:30" },
  { time: "14:00" },
  { time: "14:30" },
  { time: "15:00" },
  { time: "15:30" },
  { time: "16:00" },
  { time: "16:30" },
  { time: "17:00" },
  { time: "17:30" },
  { time: "18:00" },
  { time: "18:30" },
  { time: "19:00" },
  { time: "19:30" },
  { time: "20:00" },
  { time: "20:30" },
  { time: "21:00" },
  { time: "21:30" },
  { time: "22:00" },
  { time: "22:30" },
  { time: "23:00" },
  { time: "23:30" },
];
