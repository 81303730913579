import { Col, Input, Row, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createquan } from "../features/quan/QuanSlice";
import axios from "axios";
import { option4 } from "../data/data";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import Dropzone from "react-dropzone";

const CreateQuan = () => {
  const navigate = useNavigate();
  const [selectedTinh, setSelectedTinh] = useState(null);
  const [quan, setQuan] = useState([]);
  const [quan1, setQuan1] = useState([]);
  const [fields, setFields] = useState([
    { name: "", sdt: "", infor: "", image: [] },
  ]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const imgState = useSelector((state) => state.upload?.images?.media) || [];
  const [isLoading, setIsLoading] = useState(false);

  const userSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Yêu cầu nhập tên sân"),
        sdt: Yup.string().required("Yêu cầu nhập số điện thoại"),
        infor: Yup.string().required("Yêu cầu nhập thông tin"),
        image: Yup.array().min(1, "Yêu cầu ít nhất một hình ảnh hoặc video"),
      })
    ),
    quan: Yup.string().required("Yêu cầu nhập quận"),
    city: Yup.string().required("Yêu cầu nhập thành phố"),
  });

  const formik = useFormik({
    initialValues: {
      city: "",
      quan: "",
      fields: [{ name: "", sdt: "", infor: "", image: [] }],
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (!user) {
        toast.warning("Bạn chưa đăng nhập");
        return;
      }
      dispatch(createquan(values));
      toast.success("Đăng bài thành công 😆");
      setTimeout(() => {
        formik.resetForm();
        setFields([{ name: "", sdt: "", infor: "", image: [] }]);
        navigate(-1);
      }, 300);
    },
  });

  useEffect(() => {
    const url = "https://esgoo.net/api-tinhthanh/4/0.htm";
    axios.get(url).then((response) => {
      if (response.data.error === 0) {
        setQuan1(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedTinh) {
      const url = `https://esgoo.net/api-tinhthanh/2/${selectedTinh}.htm`;
      axios.get(url).then((response) => {
        if (response.data.error === 0) {
          setQuan(response.data.data);
        }
      });
    }
  }, [selectedTinh]);

  const handleFieldChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
    formik.setFieldValue("fields", newFields);
  };

  const addField = () => {
    setFields([...fields, { name: "", sdt: "", infor: "", image: [] }]);
  };

  const removeField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    formik.setFieldValue("fields", newFields);
  };

  const handleDrop = (index, acceptedFiles) => {
    setIsLoading(true);
    dispatch(uploadImg(acceptedFiles)).then((response) => {
      const uploadedImages = response.payload.media.map((file) => ({
        public_id: file.public_id,
        url: file.url,
      }));

      const updatedFields = [...fields];
      updatedFields[index].image = [
        ...updatedFields[index].image,
        ...uploadedImages,
      ];
      setFields(updatedFields);
      formik.setFieldValue("fields", updatedFields);
      setIsLoading(false);
    });
  };

  const removeImage = (fieldIndex, imgIndex, public_id) => {
    dispatch(delImg(public_id)).then(() => {
      const updatedFields = [...fields];
      updatedFields[fieldIndex].image = updatedFields[fieldIndex].image.filter(
        (_, i) => i !== imgIndex
      );
      setFields(updatedFields);
      formik.setFieldValue("fields", updatedFields);
    });
  };

  return (
    <Container className="py-5 w-full">
      <div className="border w-full rounded-3 p-4">
        <div className="text-center fw-bold mb-3 fs-4">Nhập danh sách sân</div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-3"
        >
          <Select
            placeholder="Thành phố"
            value={formik.values.city}
            onChange={(value) => {
              formik.setFieldValue("city", value);
              setSelectedTinh(
                value === "Thành phố Hà Nội"
                  ? "01"
                  : value === "Thành phố Hồ Chí Minh"
                  ? "79"
                  : "00"
              );
            }}
            options={option4}
            style={{ width: "100%" }}
          />
          <Select
            placeholder="Quận"
            value={formik.values.quan}
            onChange={(value) => formik.setFieldValue("quan", value)}
            options={
              selectedTinh === "01" || selectedTinh === "79"
                ? quan.map((q, index) => ({
                    value: q.full_name,
                    label: q.full_name,
                    key: `${q.full_name}-${index}`,
                  }))
                : quan1
                    .flatMap((item) => item.data2)
                    .map((q, index) => ({
                      value: q?.full_name,
                      label: q?.full_name,
                      key: `${q?.full_name}-${index}`,
                    }))
            }
            style={{ width: "100%" }}
          />
          {fields.map((field, index) => (
            <div key={index} className="mb-3 border p-2 rounded">
              <Input
                placeholder={`Nhập tên sân ${index + 1}`}
                value={field.name}
                onChange={(e) =>
                  handleFieldChange(index, "name", e.target.value)
                }
                className="form-control mb-2"
              />
              <Input
                placeholder="Nhập số điện thoại"
                value={field.sdt}
                onChange={(e) =>
                  handleFieldChange(index, "sdt", e.target.value)
                }
                className="form-control mb-2"
              />
              <Input
                placeholder="Nhập thông tin sân"
                value={field.infor}
                onChange={(e) =>
                  handleFieldChange(index, "infor", e.target.value)
                }
                className="form-control mb-2"
              />
              <Dropzone
                onDrop={(acceptedFiles) => handleDrop(index, acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone border p-3 mb-2">
                    <input {...getInputProps()} />
                    {isLoading ? (
                      <p>Đang tải lên...</p>
                    ) : (
                      <p>Kéo và thả hoặc bấm để thêm hình ảnh</p>
                    )}
                  </div>
                )}
              </Dropzone>
              <div className="d-flex flex-wrap">
                {field.image.map((img, imgIndex) => (
                  <div key={img.public_id} className="position-relative m-2">
                    <button
                      type="button"
                      onClick={() =>
                        removeImage(index, imgIndex, img.public_id)
                      }
                      className="btn-close position-absolute"
                      style={{ top: "5px", right: "5px" }}
                    />
                    {img.url.includes("video") ? (
                      <video src={img.url} width={100} height={100} controls />
                    ) : (
                      <img src={img.url} alt="media" width={100} height={100} />
                    )}
                  </div>
                ))}
              </div>
              {fields.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeField(index)}
                  className="btn btn-danger"
                >
                  Xóa sân
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={addField}
            className="btn btn-primary mb-3"
          >
            Thêm sân mới
          </button>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="submit"
              className="btn btn-warning"
              style={{ width: "200px" }}
            >
              Đăng tin
            </button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default CreateQuan;
