import React, { useEffect } from "react";
import Product from "../components/Product";
import { getSlides } from "../features/slide/SlideSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Trade = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSlides());
  }, [dispatch]);
  const slides = useSelector((state) => state.slide.Slides);

  return (
    <div>
      <div className="w-full p-4">
        <div className="btn-none mb-3">
          <Link to="/home/traodoi/post-product">
            <button
              className="btn btn-danger "
              style={{
                borderRadius: "50px",
                width: "190px",

                height: "47px",
              }}
            >
              Đăng bán sản phẩm
            </button>
          </Link>
        </div>
        {slides
          ?.filter((item) => item.vitri === "Vị trí 3")
          .map((item, index) => (
            <a
              href={item.links}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img
                src={item.images[0]?.url}
                style={{
                  height: "300px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                alt="Slide Image"
              />
            </a>
          ))}
      </div>
      <Product />
    </div>
  );
};

export default Trade;
