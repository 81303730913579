import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Input, InputNumber, Select, DatePicker, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { delImg, resetImages, uploadImg } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import { addPost } from "../features/post/postSlice";
import {
  option1,
  option2,
  option4,
  option5,
  option6,
  options,
} from "../data/data";
import { useNavigate, useParams } from "react-router-dom";
import { getUserThuesan } from "../features/users/userSlice";
import { getquans } from "../features/quan/QuanSlice";
const { RangePicker: TimeRangePicker } = TimePicker;
const { TextArea } = Input;

dayjs.extend(customParseFormat);

let userSchema = Yup.object().shape({
  city: Yup.string().required("Yêu cầu nhập thành phố"),
  quan: Yup.string().required("Yêu cầu nhập quận"),
  san: Yup.string().required("Yêu cầu nhập sân"),

  title: Yup.string().required("Yêu cầu nhập tiêu đề"),
  description: Yup.string().required("Yêu cầu nhập mô tả"),
  countct: Yup.number().required("Nhập số lượng thành viên"),
  namnu: Yup.string().required("Yêu cầu chọn giới tính"),
  minTrinhDo: Yup.string().required("Nhập số trình độ tối thiểu"),
  maxTrinhDo: Yup.string().required("Nhập số trình độ tối đa"),
  date: Yup.string().required("Nhập ngày bắt đầu"),
  timeStart: Yup.string().required("Nhập thời gian bắt đầu"),
  timeEnd: Yup.string().required("Nhập thời gian kết thúc"),
  priceNam: Yup.number().required("Nhập giá tiền nam"),
  priceNu: Yup.number().required("Nhập giá tiền nữ"),
  contact: Yup.string().required("Nhập số điện thoại liên hệ"),
  links: Yup.string().required("Nhập link facebook"),
});

const Post = () => {
  const { id } = useParams();
  const [selectedTinh, setSelectedTinh] = useState(null);
  const [quan, setQuan] = useState([]);
  const [quan1, setQuan1] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleLocation, setToggleLocation] = useState(false);
  const [valueInfor, setValueInfor] = useState("");
  const [valueSdt, setValueSdt] = useState("");
  const [valuefilterSan, setValueFilterSan] = useState([]);

  const imgState = useSelector((state) => state.upload?.images?.media) || [];
  useEffect(() => {
    dispatch(getquans);
  }, []);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const img = [];
  const imgArray = Array.isArray(imgState) ? imgState : Array.from(imgState);
  imgArray.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  const currentDate = new Date();
  const currentDayjsDate = dayjs(currentDate);
  const formik = useFormik({
    initialValues: {
      location: {
        city: null,
        quan: null,
        san: null,
      },
      city: null,
      quan: null,
      san: null,
      title: "",
      description: "",
      countct: null,
      namnu: null,
      minTrinhDo: null,
      maxTrinhDo: null,
      links: null,
      date: null,
      timeStart: null,
      timeEnd: null,
      priceNam: "",
      priceNu: "",
      contact: "",
      images: [],
      laplai: "",
      type:
        id === "traodoi"
          ? "Trao đổi"
          : id === "giaoluu"
          ? "Giao lưu"
          : "Sân đấu",
    },
    enableReinitialize: true,
    validationSchema: userSchema,
    onSubmit: (values) => {
      console.log(values);
      if (!user) {
        toast.warning("Bạn chưa đăng nhập");
        return;
      }

      if (!values.date || !values.timeStart || !values.timeEnd) {
        toast.warning("Vui lòng nhập ngày và thời gian");
        return;
      }

      dispatch(addPost(values));
      toast.success("Đăng bài thành công 😆");
      dispatch(getUserThuesan(user?.id));
      setTimeout(() => {
        formik.resetForm();
        dispatch(resetImages());
        navigate(-1);
      }, 300);
    },
  });

  const handleDrop = (acceptedFiles) => {
    setIsLoading(true);
    dispatch(uploadImg(acceptedFiles)).finally(() => {
      setIsLoading(false);
    });
  };
  useEffect(() => {
    const url = "https://esgoo.net/api-tinhthanh/4/0.htm";
    axios.get(url).then((response) => {
      if (response.data.error === 0) {
        setQuan1(response.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedTinh) {
      const url = `https://esgoo.net/api-tinhthanh/2/${selectedTinh}.htm`;

      axios.get(url).then((response) => {
        if (response.data.error === 0) {
          setQuan(response.data.data);
        }
      });
    }
  }, [selectedTinh]);

  const getLabelByValue = (options) => (value) => {
    const option = options.find(
      (option) => option.value === parseInt(value, 10)
    );
    return option ? option.label : value;
  };
  const getLBVCity = getLabelByValue(option4);
  const getLBVQuan = getLabelByValue(option5);
  const getLBVSan = getLabelByValue(option6);
  const getOptionLabel = (options) => (value) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "";
  };

  const [allQuan, setAllQuans] = useState([]);
  useEffect(() => {
    const fetchAllQuans = async () => {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/quan/get-all-quan"
        );
        setAllQuans(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllQuans();
  }, []);
  const filterQuan = allQuan.filter((item) => item.quan === formik.values.quan);
  const mergedFields = filterQuan.flatMap((item) => item.fields);
  const handleSanChange = (value) => {
    formik.setFieldValue("san", value);
    const selectedObject = mergedFields.find((item) => item.name === value);

    if (selectedObject) {
      // Định dạng lại dữ liệu cho images
      const formattedImages = selectedObject.image.map((img) => ({
        public_id: img.public_id,
        url: img.url,
      }));

      // Kiểm tra imgState và gán giá trị cho images
      if (imgState.length < 1) {
        formik.setFieldValue("images", formattedImages); // Gán từ selectedObject
      } else {
        formik.setFieldValue("images", img); // Gán từ imgState
      }

      formik.setFieldValue("description", selectedObject.infor || "");
      formik.setFieldValue("contact", selectedObject.sdt || "");
      setValueFilterSan(selectedObject);
    }
  };

  return (
    <Container className="py-5">
      <div className="border rounded-3 p-4">
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col sm={8}>
              <div className="d-flex flex-column gap-3">
                <strong>Thông tin chung</strong>
                <div className="d-flex flex-column gap-3">
                  <Input
                    placeholder="Địa chỉ sân"
                    onClick={() => setToggleLocation(true)}
                    value={`${getLBVCity(formik.values.city) || ""} - ${
                      getLBVQuan(formik.values.quan) || ""
                    } - ${getLBVSan(formik.values.san) || ""}`}
                    readOnly
                    onChange={(e) => {
                      formik.handleChange("location")(e);
                    }}
                    onBlur={formik.handleBlur("location")}
                    className="form-control"
                  />
                  {formik.touched.location && formik.errors.location && (
                    <div className="text-danger">{formik.errors.location}</div>
                  )}
                  {toggleLocation && (
                    <Modal show centered>
                      <Container className="p-3">
                        <Row className="mt-4 d-flex flex-column gap-3">
                          <span className="text-center fs-5 fw-bold">
                            Chọn địa chỉ
                          </span>
                          <Col>
                            <Select
                              placeholder="Thành phố"
                              value={formik.values.city}
                              onChange={(value) => {
                                formik.setFieldValue("city", value);
                                setSelectedTinh(
                                  value === "Thành phố Hà Nội"
                                    ? "01"
                                    : value === "Thành phố Hồ Chí Minh"
                                    ? "79"
                                    : "00"
                                );
                              }}
                              options={option4}
                              style={{
                                width: "100%",
                                zIndex: "9999999999 !important",
                              }}
                              dropdownStyle={{
                                zIndex: 999999999999,
                              }}
                            />
                          </Col>
                          <Col>
                            <Select
                              placeholder="Quận"
                              value={formik.values.quan}
                              onChange={(value) => {
                                formik.setFieldValue("quan", value);
                              }}
                              options={
                                selectedTinh === "01" || selectedTinh === "79"
                                  ? quan.map((q, index) => ({
                                      value: q.full_name,
                                      label: q.full_name,
                                      key: `${q.full_name}-${index}`,
                                    }))
                                  : quan1
                                      .flatMap((item) => item.data2)
                                      .map((q, index) => ({
                                        value: q?.full_name,
                                        label: q?.full_name,
                                        key: `${q?.full_name}-${index}`,
                                      }))
                              }
                              style={{
                                width: "100%",
                              }}
                              dropdownStyle={{
                                zIndex: 999999999999,
                              }}
                            />
                          </Col>
                          <Col>
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              dropdownStyle={{
                                zIndex: 999999999999,
                              }}
                              placeholder="Chọn sân"
                              optionFilterProp="label"
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              options={mergedFields?.map((item, index) => ({
                                key: `${item}-${index}`,
                                value: item.name,
                                label: item.name,
                              }))}
                              onChange={handleSanChange}
                              // onChange={(value) => {
                              //   formik.setFieldValue("san", value);
                              // }}
                            />
                          </Col>
                        </Row>
                        <div className="d-flex gap-3 justify-content-end mt-3">
                          <Button onClick={() => setToggleLocation(false)}>
                            Hủy
                          </Button>
                          <Button
                            type="button"
                            onClick={() => setToggleLocation(false)}
                          >
                            Đồng ý
                          </Button>
                        </div>
                      </Container>
                    </Modal>
                  )}
                  <Input
                    placeholder="Tiêu đề"
                    value={formik.values.title}
                    onChange={(e) => {
                      formik.handleChange("title")(e);
                    }}
                    onBlur={formik.handleBlur("title")}
                    className="form-control"
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-danger">{formik.errors.title}</div>
                  )}
                  <TextArea
                    rows={4}
                    defaultValue={valuefilterSan?.infor}
                    value={formik.values.description}
                    onChange={(e) => {
                      formik.handleChange("description")(e);
                    }}
                    onBlur={formik.handleBlur("description")}
                    placeholder={valuefilterSan?.infor || "Mô tả chi tiết"}
                    className="form-control"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="text-danger">
                      {formik.errors.description}
                    </div>
                  )}
                </div>
                <strong>Yêu cầu về thành viên</strong>
                <div className="d-flex gap-3">
                  <InputNumber
                    min={1}
                    max={10}
                    value={formik.values.countct}
                    placeholder="Số lượng cần tuyển"
                    onChange={(value) => {
                      formik.setFieldValue("countct", value);
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                  <Select
                    placeholder="Cả nam và nữ"
                    value={formik.values.namnu}
                    onChange={(value) => {
                      formik.setFieldValue("namnu", value);
                    }}
                    options={option1}
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                  />
                </div>
                <div className="d-flex gap-3">
                  <Select
                    placeholder="Trình độ nữ"
                    value={formik.values.minTrinhDo}
                    onChange={(value) => {
                      formik.setFieldValue("minTrinhDo", value);
                    }}
                    options={options}
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                  />
                  <Select
                    placeholder="Trình độ nam"
                    value={formik.values.maxTrinhDo}
                    onChange={(value) => {
                      formik.setFieldValue("maxTrinhDo", value);
                    }}
                    options={options}
                    style={{
                      border: "none",
                      width: "100%",
                    }}
                  />
                </div>
                <strong>Thời gian và Chi phí</strong>
                <div className="border p-4 rounded-3">
                  <div className="d-flex justify-between gap-5 mb-3">
                    <div className="w-50">
                      <div>
                        <span className="mb-3 d-block">Ngày bắt đầu</span>
                        <DatePicker
                          placeholder="Chọn ngày bắt đầu"
                          format="DD/MM/YYYY"
                          onChange={(date, dateString) => {
                            formik.setFieldValue("date", dateString);
                          }}
                          className="form-control"
                        />
                      </div>
                      {formik.touched.date && formik.errors.date && (
                        <div className="text-danger">{formik.errors.date}</div>
                      )}
                    </div>
                    <div className="w-50 d-flex align-items-end">
                      <TimeRangePicker
                        onChange={(time, timeString) => {
                          formik.setFieldValue("timeStart", timeString[0]);
                          formik.setFieldValue("timeEnd", timeString[1]);
                        }}
                        className="form-control"
                        format="HH:mm"
                      />
                    </div>
                  </div>
                  <div>
                    <label>Lặp lại</label>
                    <Select
                      placeholder="Lặp lại"
                      value={formik.values.laplai}
                      defaultValue="Lặp lại"
                      onChange={(value) => {
                        formik.setFieldValue("laplai", value);
                      }}
                      options={option2}
                      style={{
                        border: "none",
                        width: "20%",
                        marginLeft: "40px",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="w-50">
                    <Input
                      placeholder="Phí giao lưu nữ"
                      value={formik.values.priceNu.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })}
                      onChange={(e) => {
                        formik.handleChange("priceNu")(e);
                      }}
                      onBlur={formik.handleBlur("priceNu")}
                      className="form-control"
                    />
                    {formik.touched.priceNu && formik.errors.priceNu && (
                      <div className="text-danger">{formik.errors.priceNu}</div>
                    )}
                  </div>
                  <div className="w-50">
                    <Input
                      placeholder="Phí giao lưu nam"
                      value={formik.values.priceNam.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })}
                      onChange={(e) => {
                        formik.handleChange("priceNam")(e);
                      }}
                      onBlur={formik.handleBlur("priceNam")}
                      className="form-control"
                    />
                    {formik.touched.priceNam && formik.errors.priceNam && (
                      <div className="text-danger">
                        {formik.errors.priceNam}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <button type="button" className="btn btn-danger">
                    Hủy
                  </button>
                  <button type="submit" className="btn btn-warning">
                    Đăng tin
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="d-flex flex-column gap-3">
                <strong>Thông tin liên hệ</strong>
                <div>
                  <Input
                    placeholder="Số điện thoại"
                    value={formik.values.contact}
                    onChange={(e) => {
                      formik.handleChange("contact")(e);
                      console.log("Số điện thoại liên hệ:", e.target.value);
                    }}
                    onBlur={formik.handleBlur("contact")}
                    className="form-control"
                  />
                  {formik.touched.contact && formik.errors.contact && (
                    <div className="text-danger">{formik.errors.contact}</div>
                  )}
                </div>
                <div className="w-100">
                  <label className="mb-2">Nhập Link Facebook</label>
                  <Input
                    placeholder="http://facebook.com/abcdefgh"
                    value={formik.values.links}
                    onChange={(e) => {
                      formik.handleChange("links")(e);
                    }}
                    onBlur={formik.handleBlur("links")}
                    className="form-control"
                  />
                  {formik.touched.links && formik.errors.links && (
                    <div className="text-danger">{formik.errors.links}</div>
                  )}
                </div>
                <strong>Hình ảnh, video mô tả</strong>
                <div className="img_post">
                  <Dropzone
                    onDrop={handleDrop}
                    accept={{ "image/*": [], "video/*": [] }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <section className="h-100">
                          <div className="h-100" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {imgState?.length < 1 && !isLoading && (
                              <p className="mb-0 h-100 text-center">
                                Thêm hình ảnh, video
                              </p>
                            )}
                            {isLoading && (
                              <p className="mb-0 h-100 text-center">
                                Dữ liệu đang tải lên trong vài giây...
                              </p>
                            )}
                          </div>
                        </section>
                        {imgState?.length > 0
                          ? imgState.map((i, j) => (
                              <div className="position-relative" key={j}>
                                <button
                                  type="button"
                                  onClick={() => dispatch(delImg(i.public_id))}
                                  className="btn-close position-absolute"
                                  style={{ top: "10px", right: "10px" }}
                                ></button>
                                {i.url.includes("video") ? (
                                  <video
                                    src={i.url}
                                    width={200}
                                    height={200}
                                    controls
                                  ></video>
                                ) : (
                                  <img
                                    src={i.url}
                                    alt=""
                                    width={200}
                                    height={200}
                                  />
                                )}
                              </div>
                            ))
                          : valuefilterSan?.image?.map((img, idx) => (
                              <div className="position-relative" key={idx}>
                                <img
                                  src={img.url}
                                  alt=""
                                  width={200}
                                  height={200}
                                />
                              </div>
                            ))}
                      </>
                    )}
                  </Dropzone>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </Container>
  );
};

export default Post;
