import React, { useEffect } from "react";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { BsCalendar2HeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SiLevelsdotfyi } from "react-icons/si";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../features/post/postSlice";
import { option4, option5, option6 } from "../data/data";
const MyPost = () => {
  const isVideo = (url) => {
    return url && url.includes("video");
  };
  const formatCurrencyToK = (amount) => {
    const formatter = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/\s*₫/, "");

    return formattedAmount;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPost());
    window.scroll(0, 0);
  }, [dispatch]);
  const postAll = useSelector((state) => state.post.post.Posts);
  const user = useSelector((state) => state.auth.user);
  const postUser =
    postAll && postAll?.filter((e) => e.updateBy?._id == user?._id);
  console.log(user);
  const getLabelByValue = (options) => (value) => {
    const option = options.find(
      (option) => option.value === parseInt(value, 10)
    );
    return option ? option.label : value;
  };
  const getLBVCity = getLabelByValue(option4);
  const getLBVQuan = getLabelByValue(option5);
  const getLBVSan = getLabelByValue(option6);
  return (
    <div
      style={{
        overflow: "hidden",
      }}
      className="p-4"
    >
      <>
        <div>
          <div className="d-flex flex-column gap-3">
            <strong>Tìm thấy {postUser?.length} hoạt động</strong>
            <Row
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
              className="abc-my-post"
            >
              {postUser?.map((inf, index) => {
                return (
                  <Col
                    key={index}
                    xs={12}
                    md={6}
                    lg={4}
                    className="mb-4"
                    style={{
                      background: "white",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "100%",
                      boxShadow: "#ccc 1px 1px 10px",
                    }}
                  >
                    <div className="d-flex gap-3 abbb">
                      <Link to={`/badminton/${inf?._id}`}>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          {inf?.images && inf.images[0]?.url ? (
                            isVideo(inf.images[0].url) ? (
                              <video
                                src={inf.images[0].url}
                                alt="video preview"
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                                className="media-mobile-cours mobileCSS"
                                controls
                              />
                            ) : (
                              <img
                                src={inf.images[0].url}
                                alt="image preview"
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                                className="img-mobile-cours"
                              />
                            )
                          ) : (
                            <p>No media available</p>
                          )}
                          <button
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                              borderRadius: "20px",
                              background: "#f5002f",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                            className="btn btn-danger"
                          >
                            {formatCurrencyToK(inf?.priceNu) +
                              " - " +
                              formatCurrencyToK(inf?.priceNam)}
                          </button>
                        </div>
                      </Link>

                      <div className="d-flex flex-column justify-content-evenly">
                        <span
                          style={{
                            color: "#5b5b5b",
                          }}
                        >
                          Đang xác định vị trí của bạn
                        </span>
                        <Link to={`/badminton/${inf?._id}`}>
                          <h4 className="text-dark">{inf?.title}</h4>
                        </Link>
                        <div className="d-flex gap-3 align-items-center">
                          <FaMapMarkerAlt
                            style={{
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              color: "#5b5b5b",
                            }}
                          >
                            {getLBVCity(inf?.city) +
                              " " +
                              getLBVQuan(inf?.quan) +
                              " " +
                              getLBVSan(inf?.san)}
                          </span>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <FaCalendarAlt
                            style={{
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              color: "#5b5b5b",
                            }}
                          >
                            {inf?.date +
                              " " +
                              inf?.timeStart +
                              " " +
                              inf?.timeEnd}
                          </span>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <BsCalendar2HeartFill
                            style={{
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              color: "#5b5b5b",
                            }}
                          >
                            {inf?.laplai === 1 ? "Hàng ngày" : "Hàng tuần"}
                          </span>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <SiLevelsdotfyi
                            style={{
                              color: "red",
                            }}
                          />
                          <span
                            style={{
                              color: "#5b5b5b",
                            }}
                          >
                            Trình độ:
                            {inf?.minTrinhDo + " - " + inf?.maxTrinhDo}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
};

export default MyPost;
