import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost, filterPost, resetPost } from "../features/post/postSlice";
import SetTime from "../components/SetTime";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { FaMapMarkerAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { getAllThueSan } from "../features/thuesan/thuesanSlice";
import { Link } from "react-router-dom";
const { Option } = Select;
const StatusYard = () => {
  const isVideo = (url) => {
    return url && url.includes("video");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPost());
    window.scroll(0, 0);
  }, [dispatch]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [idSan, setIdSan] = useState("");
  const postAll = useSelector((state) => state.post.post.Posts);
  const filterSanDau = postAll.filter((item) => item.type === "Sân đấu");

  const [idYard, setIdYard] = useState({
    date: null,
    id: "",
  });

  const handleClickFilterYard = (id) => {
    setIdSan(id);
    if (selectedDate === null) {
      toast.warning("Bạn chưa chọn ngày đặt sân");
    } else {
      setIdYard({
        date: selectedDate,
        id: id,
      });
    }
  };

  const [filters, setFilters] = useState({
    san: undefined,
    date: undefined,
  });
  const onChange = (date, dateString) => {
    setSelectedDate(dateString);
    setIdYard({
      date: null,
      id: "",
    });
    setIdSan("");
    dispatch(getAllThueSan());
  };
  const handleChange = (value, field) => {
    let newValue = value;
    if (field === "date") {
      newValue = value ? value.format("YYYY-MM-DD") : undefined;
    } else if (field === "timeStart" || field === "timeEnd") {
      newValue = value ? value.format("HH:mm:ss") : undefined;
    }

    const newFilters = {
      ...filters,
      [field]: newValue,
    };

    setFilters(newFilters);
    dispatch(filterPost(newFilters));
  };

  const handleReset = () => {
    setFilters({
      san: undefined,
      date: undefined,
    });
    dispatch(resetPost());
  };
  return (
    <Container className="my-5 d-flex flex-column gap-5">
      <Row className="mobile-yard">
        <div className="btn-none mb-3">
          <Link to="/home/sandau/post">
            <button
              className="btn btn-danger "
              style={{
                borderRadius: "50px",
                width: "190px",

                height: "47px",
              }}
            >
              Đăng Pass Sân
            </button>
          </Link>
        </div>
        <span className="text-center d-block fw-bold fs-5 mb-5">TRẠNG THÁI SÂN</span>
        <Col sm={4} style={{ height: "50px" }}>
          <Select
            placeholder="Sân"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
            }}
            onChange={(value) => handleChange(value, "san")}
            value={filters.san}
            key={filters.san}
          >
            {filterSanDau?.map((san) => (
              <Option key={san.san} value={san.san}>
                {san.san}
              </Option>
            ))}
          </Select>
        </Col>
        <Col sm={4} style={{ height: "50px" }}>
          <DatePicker
            placeholder="Ngày"
            value={
              filters.date ? moment(filters.date, "YYYY-MM-DD") : undefined
            }
            onChange={(date) => handleChange(date, "date")}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Col>
        <Col sm={4}>
          <div style={{ height: "100%" }} onClick={handleReset}>
            <button
              className="btn btn-primary"
              style={{ height: "100%", width: "100px" }}
            >
              Xóa lọc
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="text-center d-block fw-bold fs-5">
          CHỌN NGÀY ĐẶT SÂN
        </span>
        <Col sm={3} className="mt-4">
          <DatePicker
            placeholder="Ngày đặt sân"
            onChange={onChange}
            style={{
              width: "100%",
              padding: "13px",
            }}
          />
        </Col>
        <Col sm={3}></Col>
        <Col sm={3}></Col>
        <Col sm={3}></Col>
      </Row>
      <Row className="">
        <span className="text-center d-block fw-bold fs-5">CHỌN SÂN</span>
        <div
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
            display: "grid",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          {filterSanDau?.map((item) => (
            <div
              key={item?._id}
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "20px",
                border:
                  idYard.id === item?._id ? "2px solid red" : "2px solid #000",
                cursor: "pointer",
              }}
              onClick={() => handleClickFilterYard(item?._id)}
            >
              <div className="row p-2">
                <div className="col-6">
                  {item?.images && item.images[0]?.url ? (
                    isVideo(item.images[0].url) ? (
                      <video
                        src={item.images[0].url}
                        alt="video preview"
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="media-mobile-cours mobileCSS"
                        // controls
                      />
                    ) : (
                      <img
                        src={item.images[0].url}
                        alt="image preview"
                        style={{
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                        className="img-mobile-cours"
                      />
                    )
                  ) : (
                    <p>No media available</p>
                  )}
                </div>
                <div className="col-6 d-flex flex-column">
                  <span className="fw-bold">{item?.title}</span>
                  <span className="fw-bold">
                    <FaMapMarkerAlt /> {item?.san},{item?.city},{item?.san}
                  </span>
                  <p className="text-danger">
                    Người cho thuê: {item?.updateBy?.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Row>
      {selectedDate && (
        <Row>
          <span className="text-center d-block fw-bold fs-5">
            Ngày đặt sân đã chọn: {selectedDate}
          </span>
        </Row>
      )}
      <span className="text-center d-block fw-bold fs-5">TRẠNG THÁI GIỜ</span>
      <Row>
        <div
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
            display: "grid",
            gap: "20px",
          }}
        >
          <SetTime id={idYard} date={selectedDate} idSan={idSan} />
        </div>
      </Row>
      <Row className="d-flex gap-3 px-2">
        <button className="btn_dadat" style={{ width: "150px" }}>
          Đã đặt
        </button>
        <button className="btn_xacnhan" style={{ width: "150px" }}>
          Chờ xác nhận
        </button>
        <button
          className="btn_trong"
          style={{
            width: "150px",
          }}
        >
          Trống
        </button>
        <button
          className="choice_thue"
          style={{
            width: "150px",
            borderRadius: "12px",
          }}
        >
          Đang chọn
        </button>
      </Row>
    </Container>
  );
};

export default StatusYard;
