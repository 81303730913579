import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DatePicker, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Table from "react-bootstrap/Table";
import { getAllThueSan } from "../features/thuesan/thuesanSlice";
import { filterPost, getAllPost, PostTs } from "../features/post/postSlice";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import SetTime2 from "./SetTime2";
import axios from "axios";
import { base_url } from "../utils/base_url";
const { Option } = Select;

const CourtStatus = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const postAll = useSelector((state) => state?.post?.post?.Posts);
  const PostTsAll = useSelector((state) => state.post.PostTS.allIdTS);
  const allThueSan = useSelector((state) => state.thuesan.thuesan);

  const [filterDataYard, setFilterDataYard] = useState([]);
  const [filters, setFilters] = useState({
    san: undefined,
    date: undefined,
  });
  const [show, setShow] = useState(false);
  const [idYard, setIdYard] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSan, setSelectedSan] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const isVideo = (url) => {
    return url && url.includes("video");
  };


  
  useEffect(() => {
    if (postAll) {
      setFilterDataYard(postAll?.filter((e) => e.updateBy?._id === user?._id));
    }
    dispatch(getAllThueSan());
  }, [postAll, user?._id, dispatch]);

  useEffect(() => {
    dispatch(
      filterPost({
        san: selectedSan,
        date: selectedDate,
      })
    );
  }, [selectedSan, selectedDate, dispatch]);

  const handleShow = (item) => {
    dispatch(PostTs(item));
    setSelectedItem(item);
    setShow(true);
  };

  const handleShow2 = (item) => {
    dispatch(PostTs(item));
    setIdYard(item);
  };

  const handleClose = () => setShow(false);

  const onChanges = (date, dateString) => {
    setSelectedDateTime(dateString);
  };

  const handleChange = async (value, detailId) => {
    try {
      await axios.put(`${base_url}thuesan/update/${detailId}`, {
        status: value,
      });
      dispatch(getAllThueSan());
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      san: undefined,
    });
    dispatch(
      filterPost({
        san: undefined,
      })
    );
    setSelectedSan(null);
    dispatch(getAllPost());
  };

  const filteredData = filterDataYard?.filter((item) => {
    return ((!selectedSan || item.san === selectedSan) &&
    (!selectedDate || moment(item.date).format("YYYY-MM-DD") === selectedDate)
  );
});

const sandau = filteredData

const sanOptions = [...new Set(sandau?.map((item) => item.san))].map(
  (san) => (
    <Option key={san} value={san}>
      {san}
    </Option>
  )
);
useEffect(() => {
  dispatch(getAllPost());
  window.scroll(0, 0);
}, [dispatch]);
const formatDate = (dateString) => {
  if (!dateString) return "";
  return moment(dateString).format("YYYY-MM-DD");
};
return (
  <Container className="my-5">
    <Row className="d-flex align-items-center gap-3">
      <Col sm={3}>
        <Select
          placeholder="Chọn sân"
          style={{ width: "100%", height: "50px" }}
          onChange={(value) => setSelectedSan(value)}
          value={selectedSan}
        >
          {sanOptions}
        </Select>
      </Col>
      <Col span={3}>
        <DatePicker
          placeholder="Ngày đặt sân"
          onChange={onChanges}
          style={{ width: "100%", height: "50px" }}
        />
      </Col>
      <Col sm={3} className="d-flex gap-3 flex-column">
        <div className="d-flex gap-3">
          <button
            className="btn btn-primary"
            style={{ height: "50px" }}
            onClick={handleClearFilters}
          >
            Xóa lọc
          </button>
        </div>
      </Col>
      <Col sm={3}></Col>
    </Row>
    <hr />
    <Row>
      <div className="justify-content-center align-items-center d-flex flex-column gap-2">
        <strong>HIỆN CÓ {sandau?.length || 0} SÂN ĐANG ONLINE</strong>
      </div>
    </Row>
    <Row className="mt-4">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "20px",
        }}
      >
        {sandau?.map((item, index) => (
          <div
            key={item._id}
            style={{
              border: "1px solid #ccc",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                padding: "10px",
                background: "#ccc",
              }}
            >
              <strong>{item.san}</strong>
            </div>
            <div onClick={() => handleShow(item._id)}>
             {item?.images && item.images[0]?.url ? (
              isVideo(item.images[0].url) ? (
                <video
                  src={item.images[0].url}
                  alt="video preview"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  className="media-mobile-cours mobileCSS"
                  // controls
                />
              ) : (
                <img
                  src={item.images[0].url}
                  alt="image preview"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                  className="img-mobile-cours"
                />
              )
            ) : (
              <p>No media available</p>
            )}
            </div>
          </div>
        ))}
      </div>
    </Row>
    {show && (
      <div
        style={{
          maxHeight: "100vh",
          position: "fixed",
          background: "#000",
          opacity: "1",
          top: 0,
          left: 0,
          right: 0,
            bottom: 0,
            padding: "100px",
            zIndex: 9999,
            overflowY: "auto",
          }}
          className="thuesan-detail"
        >
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <IoClose style={{ fontSize: "40px" }} />
          </div>
          <div style={{ width: "100%", height: "50%" }}>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="text-center">
                  <th className="align-text-top">STT</th>
                  <th className="align-text-top">Tên</th>
                  <th className="align-text-top">SĐT</th>
                  <th className="align-text-top">Ngày ĐK</th>
                  <th className="align-text-top">Thông tin</th>
                  <th className="align-text-top">Tình trạng</th>
                </tr>
              </thead>
              <tbody>
                {PostTsAll?.length > 0 ? (
                  PostTsAll?.map((detail, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{detail?.updateBy?.name}</td>
                      <td>{detail?.updateBy?.mobile}</td>
                      <td>{formatDate(detail?.createdAt)}</td>
                      <td>
                        <div className="d-flex flex-column">
                          <span className="text-danger">
                            Ngày thuê: {detail?.date}
                          </span>
                          <span className="text-success">
                            Thời gian: {detail?.time.join(" - ")}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Select
                          defaultValue={detail.status}
                          style={{ width: 200 }}
                          dropdownStyle={{ zIndex: 9999999 }}
                          onChange={(value) => handleChange(value, detail._id)}
                          options={[
                            { label: "Chờ xác nhận", value: "Chờ xác nhận" },
                            { label: "Xác nhận", value: "Xác nhận" },
                          ]}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="text-white py-5" style={{ fontWeight: 600 }}>
                    Chưa có người đặt sân
                  </p>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      <hr />
      <Row>
        <strong className="text-center mb-4">TÌNH TRẠNG SÂN</strong>
        <Accordion defaultActiveKey="0">{sandau?.map((item, index) => {
            return (
              <Accordion.Item
                key={item._id}
                eventKey={index + 1}
                onClick={() => handleShow2(item._id)}
              >
                <Accordion.Header>{item?.san}</Accordion.Header>
                <Accordion.Body>
                  <SetTime2
                    date={selectedDateTime}
                    data={PostTsAll}
                    idSan={idYard}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Row>
      <Row className="mt-4">
        <div>
          <strong className="d-block text-center">CHÚ THÍCH / DIỄN GIẢI</strong>
          <div className="progress mt-3 bg-white" style={{ height: "30px" }}>
            <div
              className="progress-bar progress-bar-striped btn_dadat"
              role="progressbar"
              style={{ width: "33.3%" }}
            >
              Đã đặt
            </div>
            <div
              className="progress-bar progress-bar-striped text-dark btn_xacnhan"
              role="progressbar"
              style={{ width: "33.3%" }}
            >
              Chờ xác nhận
            </div>
            <div
              className="progress-bar text-dark progress-bar-striped btn_trong"
              role="progressbar"
              style={{ width: "33.3%" }}
            >
              Còn trống
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default CourtStatus;