import "./App.css";
import Start from "./pages/Start";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import BadmintonDetails from "./pages/BadmintonDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import Post from "./pages/Post";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Footer from "./components/Footer";
import { useState } from "react";
import MyPost from "./pages/MyPost";
import PostProduct from "./pages/PostProduct";
import Footer from "./components/Footer";
import ProductDetails from "./pages/ProductDetails";
import CourtStatus from "./components/CourtStatus";
import StatusYard from "./pages/StatusYard ";
import MyProduct from "./pages/MyProduct";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import Exchange from "./pages/Exchange";
import Trade from "./pages/Trade";
import Map2 from "./components/Map2";
import CreateQuan from "./pages/CreateQuan";

function App() {
  const [filterData, setFilterData] = useState(null);

  const handleFilterChange = (newFilterData) => {
    setFilterData(newFilterData);
  };

  const [checked, setChecked] = useState(true);

  const handleCheckedChange = (newChecked) => {
    setChecked(newChecked);
  };

  return (
    <BrowserRouter>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Routes>
          <Route
            path="*"
            element={
              <>
                <Header
                  onFilterChange={handleFilterChange}
                  checked={checked}
                  onCheckedChange={handleCheckedChange}
                />
                <div
                  style={{
                    flex: "1",
                    padding: "170px 0 0 0",
                  }}
                >
                  <Routes>
                    <Route path="/" element={<Navigate to="/home/giaoluu" />} />
                    <Route
                      path="/home/:id"
                      element={<Home data={filterData} checked={checked} />}
                    />
                    <Route path="/home/:id/post" element={<Post />} />
                    <Route
                      path="/badminton/:id"
                      element={<BadmintonDetails />}
                    />
                    <Route path="/product/:id" element={<ProductDetails />} />
                    <Route path="/post" element={<Post />} />
                    <Route path="/mypost" element={<MyPost />} />
                    <Route
                      path="/home/traodoi/post-product"
                      element={<PostProduct />}
                    />
                    <Route
                      path="/home/traodoi/mua-ban-traodoi"
                      element={<Trade />}
                    />
                    <Route path="/map2" element={<Map2 />} />
                    <Route path="/tao-san" element={<CreateQuan />} />

                    <Route
                      path="/home/giaoluu/tuyen-giao-luu"
                      element={<Exchange />}
                    />
                    <Route
                      path="/quan-ly-thue-san/:id"
                      element={
                        <PrivateRoutes>
                          {" "}
                          <CourtStatus />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/quan-ly-san-pham"
                      element={
                        <PrivateRoutes>
                          <MyProduct />
                        </PrivateRoutes>
                      }
                    />
                    <Route
                      path="/home/sandau/thue-san"
                      element={<StatusYard />}
                    />
                  </Routes>
                </div>
                {/* <Footer /> */}
              </>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
