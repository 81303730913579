import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../features/post/postSlice";
import { toast } from "react-toastify";
import { dataTime } from "../data/data";
import { Row } from "antd";
import axios from "axios";
import { config } from "../utils/axiosconfig";
import { base_url } from "../utils/base_url";
import { getAllThueSan } from "../features/thuesan/thuesanSlice";

const SetTime = ({ id, date, idSan }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [orderTime, setOrderTime] = useState([]);

  const [thuesan, setThuesan] = useState({
    san: "",
    time: [],
    date: id.date,
  });
  const allThueSan = useSelector((state) => state.thuesan.thuesan);

  const [matchedTimes, setMatchedTimes] = useState([]);
  const filteredData = allThueSan.filter(
    (item) => item.date === thuesan.date && item.san === thuesan.san
  );
  const filteredDataTime = filteredData.filter((item) =>
    item.time.some((time) => matchedTimes.includes(time))
  );

  useEffect(() => {
    const checkTimes = () => {
      const matched = [];

      allThueSan.forEach((item) => {
        if (item.san === idSan && item.date === date) {
          const { time } = item;
          time.forEach((t) => {
            if (dataTime.some((dt) => dt.time === t)) {
              matched.push(t);
            }
          });
        }
      });
      setMatchedTimes(matched);
    };

    checkTimes();
  }, [allThueSan, idSan, date]);

  useEffect(() => {
    dispatch(getAllPost());
    window.scroll(0, 0);
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      setThuesan((prevThuesan) => ({
        ...prevThuesan,
        san: id.id,
        time: orderTime,
      }));
    }
  }, [orderTime, id, user]);

  useEffect(() => {
    setOrderTime([]);
    setThuesan({
      san: id.id,
      time: [],
      date: id.date,
    });
  }, [id, user]);

  const handle = (time) => {
    if (!id.id) return toast.warning("Bạn chưa chọn sân thuê");
    if (matchedTimes.includes(time)) {
      toast.warning("Thời gian đang được chọn");
    } else if (!orderTime.includes(time)) {
      setOrderTime((prevOrderTime) => [...prevOrderTime, time]);
    } else {
      setOrderTime((prevOrderTime) => prevOrderTime.filter((t) => t !== time));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user === null) return toast.warning("Bạn chưa đang nhập");
    if (orderTime.length < 1) {
      toast.warning("Bạn chưa chọn thời gian");
    } else {
      try {
        const response = await axios.post(
          `${base_url}thuesan/thuesan`,
          {
            date: thuesan.date,
            san: thuesan.san,
            time: thuesan.time,
          },
          config
        );
        toast.success("Đã đặt sân thành công");
        setTimeout(() => {
          dispatch(getAllThueSan());
        }, 300);
      } catch (error) {
        console.error("Error submitting reply:", error);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <div
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
              display: "grid",
              gap: "20px",
            }}
          >
            {dataTime.map((timeObj, index) => {
              const isMatched = matchedTimes.includes(timeObj.time);
              const isConfirmed = filteredDataTime.some(
                (item) =>
                  item.status === "Xác nhận" && item.time.includes(timeObj.time)
              );

              return (
                <button
                  type="button"
                  onClick={() => handle(timeObj.time)}
                  style={{
                    borderRadius: "10px",
                  }}
                  className={
                    isConfirmed
                      ? "btn_dadat"
                      : isMatched
                      ? "btn_xacnhan"
                      : orderTime.includes(timeObj.time)
                      ? "choice_thue"
                      : ""
                  }
                  key={index}
                >
                  {timeObj.time}
                </button>
              );
            })}
          </div>
        </div>
        <Row className="d-flex justify-content-end">
          <button
            type="submit"
            style={{
              width: "max-content",
            }}
            className="btn btn-primary"
          >
            Đăng ký thuê
          </button>
        </Row>
      </form>
    </>
  );
};

export default SetTime;
