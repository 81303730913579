import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { postService } from "./postService";
import { toast } from "react-toastify";
import produce from "immer";
export const addPost = createAsyncThunk(
  "post/addPost",
  async (post, thunkApi) => {
    try {
      return await postService.addPost(post);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const postRegister = createAsyncThunk(
  "post/postregister",
  async (post, thunkApi) => {
    try {
      return await postService.postRegister(post);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllPost = createAsyncThunk("post/AllPost", async (thunkApi) => {
  try {
    return await postService.getAllPost();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const getPost = createAsyncThunk(
  "post/getPost",
  async (id, thunkApi) => {
    try {
      return await postService.getPost(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const ratingPost = createAsyncThunk(
  "post/ratingPost",
  async (id, thunkApi) => {
    try {
      return await postService.ratingPost(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const PostTs = createAsyncThunk("post/possts", async (id, thunkApi) => {
  try {
    return await postService.allPostTs(id);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const filterPost = createAsyncThunk(
  "post/filterpost",
  async (filters, thunkApi) => {
    try {
      return await postService.filterPost(filters);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const resetPost = createAsyncThunk(
  "post/resetpost",
  async (filters, thunkApi) => {
    try {
      return await postService.getAllPost(filters);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const resetState = createAction("Reset_all");

const initialState = {
  post: {
    Posts: [],
    // Other fields if needed
  },
  PostTS: [],
  addp: "",
  getPost: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const authSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addp = action.payload;
        if (state.isSuccess) {
          toast.success("Đăng bài thành công 😆");
        }
      })
      .addCase(addPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (state.isError) {
          toast.error("Đăng bài thất bại 🥲");
        }
      })

      .addCase(ratingPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ratingPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(ratingPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(filterPost.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(filterPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.post.Posts = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(filterPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAllPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.post = action.payload;
      })
      .addCase(getAllPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(PostTs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PostTs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.PostTS = action.payload;
      })
      .addCase(PostTs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.getPost = action.payload;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.post = action.payload;
        // state.Posts = null;
      })
      .addCase(resetPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(postRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Đăng ký giao lưu thành công");
      })
      .addCase(postRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(resetState, () => initialState);
  },
});

export default authSlice.reducer;
