import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../features/post/postSlice";
import { filterProduct, resetProduct } from "../features/product/productSlice";

const Filter1 = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    city: undefined,
    type: undefined,
    price: undefined,
    action: undefined,
  });

  const handleChange = (value, field) => {
    const newFilters = {
      ...filters,
      [field]: value,
    };
    setFilters(newFilters);
    dispatch(filterProduct(newFilters));
  };

  const handleReset = () => {
    setFilters({
      city: undefined,
      type: undefined,
      price: undefined,
      action: undefined,
    });

    dispatch(resetProduct());
  };

  return (
    <div className="  sc d-flex gap-3 pt-3 filter align-items-center">
      <Select
        placeholder="Thành phố"
        value={filters.city}
        style={{ width: "max-content" }}
        onChange={(value) => handleChange(value, "city")}
        options={[
          {
            value: "Thành phố Hồ Chí Minh",
            label: "Thành phố Hồ Chí Minh",
          },
          {
            value: "Thành phố Hà Nội",
            label: "Thành phố Hà Nội",
          },
          {
            value: "Tỉnh thành khác",
            label: "Tỉnh thành khác",
          },
        ]}
        dropdownStyle={{
          zIndex: 9999999999,
          borderRadius: "20px",
        }}
      />
      <Select
        placeholder="Sản phẩm"
        value={filters.type}
        style={{ width: "max-content" }}
        onChange={(value) => handleChange(value, "type")}
        options={[
          {
            value: "Giày",
            label: "Giày",
          },
          {
            value: "Vợt",
            label: "Vợt",
          },
          {
            value: "Quần áo",
            label: "Quần áo",
          },
          {
            value: "Phụ kiện",
            label: "Phụ kiện",
          },
        ]}
        dropdownStyle={{
          zIndex: 9999999999,
        }}
      />
      <Select
        placeholder="Khoảng giá"
        value={filters.price}
        style={{ width: "max-content" }}
        onChange={(value) => handleChange(value, "price")}
        options={[
          {
            value: "1",
            label: "Dưới 500k",
          },
          {
            value: "2",
            label: "500.000 - 1.000.000",
          },
          {
            value: "3",
            label: "1.000.000 - 2.000.000",
          },
          {
            value: "4",
            label: "2.000.000 - 3.000.000",
          },
          {
            value: "5",
            label: "Trên 3.000.000",
          },
        ]}
        dropdownStyle={{
          zIndex: 9999999999,
        }}
      />
      <Select
        placeholder="Tình trạng"
        value={filters.action}
        style={{ width: "max-content" }}
        onChange={(value) => handleChange(value, "action")}
        options={[
          {
            value: "New 100%",
            label: "New 100%",
          },
          {
            value: "Used 95% - 99%",
            label: "Used 95% - 99%",
          },
          { value: "Cũ", label: "Cũ" },
        ]}
        dropdownStyle={{
          zIndex: 9999999999,
          borderRadius: "20px",
        }}
      />
      <Button
        onClick={handleReset}
        style={{
          borderRadius: "30px",
          transform: "translateY(9px)",
          padding: "25px",
        }}
      >
        Xóa lọc
      </Button>
    </div>
  );
};

export default Filter1;
