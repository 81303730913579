import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";
import axios from "axios";

const addThueSan = async (thuesan) => {
  const res = await axios.post(
    `${base_url}thuesan/thuesan`,
    thuesan,
    config
  );
  if (res.data) {
    return res.data;
  }
};
const getAllThueSan = async () => {
  const res = await axios.get(
    `${base_url}thuesan/getAllthuesan`
  );
  if (res.data) {
    return res.data;
  }
};
const getThueSan = async (id) => {
  const res = await axios.get(
    `${base_url}thuesan/get-id-san/${id}`,
    config
  );
  if (res.data) {
    return res.data;
  }
};
// const deletePost = async (id) => {
//   const res = await axios.delete(`${base_url}post/delete-post/${id}`, config);
//   if (res.data) {
//     return res.data;
//   }
// };
export const thuesanService = {
  getAllThueSan,
  getThueSan,
  addThueSan,
  // deletePost,
};
