import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getproduct } from "../features/product/productSlice";
import { Select } from "antd";
import axios from "axios";
import { base_url } from "../utils/base_url";
import { config } from "../utils/axiosconfig";
import { toast } from "react-toastify";

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    dispatch(getproduct(id));
    window.scroll(0, 0);
  }, []);
  const productId = useSelector((state) => state.product.getproduct.getProduct);
  const handleChange = async (value) => {
    try {
      const response = await axios.post(
        `${base_url}product/updatesp`,
        {
          _id: productId._id,
          status: value,
        },
        config
      );
      setTimeout(() => {
        dispatch(getproduct(id));
        toast.success("Đã cập nhật trạng thái");
      }, 300);
    } catch (error) {
      console.error("Error submitting reply:", error);
    }
  };
  const formatCurrencyToK = (amount) => {
    const formatter = new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    });
    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/\s*₫/, "Đ");

    return formattedAmount;
  };
  return (
    <div className="mt-5">
      <Container>
        <Row>
          <Col sm={8}>
            <div>
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={productId?.images[0]?.url}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "300px",
                    borderRadius: "20px",
                  }}
                />
              </div>
              <div className="py-4">
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-5">Tên sản phẩm</span>
                  <strong>{productId?.title}</strong>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-5">Loại</span>
                  <strong>{productId?.type}</strong>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-5">Giá</span>
                  <strong>{formatCurrencyToK(productId?.price)}</strong>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-5">Tình trạng</span>
                  <strong>{productId?.action}</strong>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-5">Mô tả</span>
                  <strong>{productId?.description}</strong>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div
              style={{
                border: "1px solid black",
                padding: "15px",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <strong className="fs-5 ">Người đăng tin</strong>
                <div className="d-flex align-items-center gap-3">
                  <span className="fs-2">
                    <FaRegUserCircle />
                    {/* {post?.user.image} */}
                  </span>
                  <span className="fs-4">{productId?.updateBy?.name}</span>
                </div>
                <hr />
                <div>
                  <strong className="fs-5">Liên hệ</strong>
                  <div className="d-flex flex-column">
                    <span>SDT: {productId?.updateBy?.mobile}</span>
                    <Link to={`${productId?.links}`} target="_blank">
                      Link facebook cá nhân
                    </Link>
                    <span>
                      Trạng thái sản phẩm :{" "}
                      <strong>{productId?.statusProduct}</strong>
                    </span>
                  </div>
                  {user?._id === productId?.updateBy?._id && (
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <lable>Cập nhật trạng thái sản phẩm</lable>
                      <Select
                        defaultValue="Đang rao"
                        placeholder="Cập nhật trạng thái sản phẩm"
                        style={{
                          width: 200,
                        }}
                        onChange={handleChange}
                        options={[
                          {
                            label: <span>Lựa chọn</span>,
                            title: "Lựa chọn",
                            options: [
                              {
                                label: <span>Đang rao</span>,
                                value: "Đang rao",
                              },
                              {
                                label: <span>Đã bán</span>,
                                value: "Đã bán",
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDetails;
